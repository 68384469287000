import { PcsPlanStatus } from "../models/pcsPlanStatus"

export function PcsPlanStatuses(statusCode: string) {
    const pcsPlanStatuses: PcsPlanStatus[] = [
        { code: 'NS', value: 'Not Started - No plan details have been saved to date.' },
        { code: 'DT', value: 'Draft - Plan has started, but has not been submitted.' },
        { code: 'SD', value: 'Submitted - You have successfully submitted your annual plan and received 5 credits (see 6 year summary)' },
        { code: 'RV', value: 'Reviewer Validation - The reviewer details you have submitted are in the process of being validated by the PCS Office.' },
        { code: 'RP', value: 'Review Request Pending  - Your review request has been sent and is awaiting review.' },
        { code: 'UR', value: 'Under Review - Reviewer has accepted your request to review your plan.' },
        { code: 'RRD', value: 'Review Request Declined - Reviewer has declined your request to review your plan.' },
        { code: 'RRI', value: 'Review Request Invalid - please add correct reviewer to review your plan.' },
        { code: 'RRC', value: 'Review Request Cancelled - Review request has been cancelled.' },
        { code: 'RD', value: 'Reviewed - Your plan has been reviewed and you have received an additional credit.' },
        { code: 'RC', value: 'This is the final stage of the Professional Development Plan. 1 cpd credit has been added to Work Based Learning category.' }
    ];

    return <>
        {pcsPlanStatuses.find(x => x.code === statusCode)?.value}
    </>
}
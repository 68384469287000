import * as yup from 'yup';
import pcsPlanFormModel from './pcsPlanFormModel';
import { EMAIL_EXPRESSION } from '../../../app/utils/constant';

const {
  formField: {
    AccreditedContinuedEducationActivity,
    WorkBasedLearning,
    PracticeReview,
    EngagedInPracticeOfMedicine,
    Title,
    FirstName,
    Surname,
    IMCNumber,
    EmailAddress,
    MyReflection
  }
} = pcsPlanFormModel;

export const plannedActivitiesValidationSchema = yup.object().shape({
  [AccreditedContinuedEducationActivity.name]: yup.string().required(`${AccreditedContinuedEducationActivity.requiredErrorMsg}`),
  [WorkBasedLearning.name]: yup.string().required(`${WorkBasedLearning.requiredErrorMsg}`),
  [PracticeReview.name]: yup.string().when([EngagedInPracticeOfMedicine.name], ([engagedInPracticeOfMedicine]) => {
    if (engagedInPracticeOfMedicine === true) {
      return yup.string().required(`${PracticeReview.requiredErrorMsg}`);
    }
    return yup.string().notRequired();
  })
});

export const reviewerDetailsValidationSchema = yup.object().shape({
  [Title.name]: yup.string().required(`${Title.requiredErrorMsg}`),
  [FirstName.name]: yup.string().required(`${FirstName.requiredErrorMsg}`),
  [Surname.name]: yup.string().required(`${Surname.requiredErrorMsg}`),
  [IMCNumber.name]: yup.string().required(`${IMCNumber.requiredErrorMsg}`),
  [EmailAddress.name]: yup.string().matches(EMAIL_EXPRESSION, `${EmailAddress.typeError}`)
});

export const feedbackValidationSchema = yup.object().shape({
  [MyReflection.name]: yup.string().required(`${MyReflection.requiredErrorMsg}`)
});

export const confirmReviewerDetailsValidationSchema = yup.object().shape({
  [IMCNumber.name]: yup.number().required(`${IMCNumber.requiredErrorMsg}`)
    .min(1, `${IMCNumber.minErrorMessage}`)
    .max(999999, `${IMCNumber.maxErrorMessage}`)
    .typeError(`${IMCNumber.typeError}`),
  [Title.name]: yup.string().test(`${Title.name}`,
    `${Title.requiredErrorMsg}`,
    function (title) {
      if (title === "") {
        return false;
      }
      return true;
    }
  ),
  [FirstName.name]: yup.string().test(`${FirstName.name}`,
    `${FirstName.requiredErrorMsg}`,
    function (firstName) {
      if (firstName === "") {
        return false;
      }
      return true;
    }
  ),
  [Surname.name]: yup.string().test(`${Surname.name}`,
    `${Surname.requiredErrorMsg}`,
    function (surname) {
      if (surname === "") {
        return false;
      }
      return true;
    }
  ),
  [EmailAddress.name]: yup.string().test(`${EmailAddress.name}`,
    `${EmailAddress.typeError}`,
    function (emailAddress) {
      if (emailAddress === undefined) {
        return true;
      }
      else if (emailAddress === "") {
        return false;
      }
      else {
        return EMAIL_EXPRESSION.test(emailAddress);
      }
    }
  )
});

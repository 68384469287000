import { Box, Button, Grid, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { useGetPayments } from "../../../app/customHooks/useGetPayments";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import React, { useEffect, useState } from "react";
import { Subscription } from "../../../app/models/payment/subscription";
import { formatDateMonthName } from "../../../app/utils/util";
import { ProductEnum } from "../../../app/enums/productEnum";
import PaymentProductJsonDto from "../../../app/models/affiliateOnboarding/paymentProductJsonDto";
import { Link } from "react-router-dom";
import { CurrencyCodeEnum } from "../../../app/enums/currencyCodeEnum";
import { CurrencyCodeIconEnum } from "../../../app/enums/currencyCodeIconEnum";
import { deleteCardSubscriptionAsync, getCardSubscriptionAsync } from "../../../app/slices/cardSubscriptionSlice";
import { SubscriptionStatusEnum } from "../../../app/enums/subscriptionStatusEnum";
import { toast } from "react-toastify";
import UnsavedChangesDialog from "../../../app/components/UnsavedChangesDialog";

export function MyRecurringPayments() {

    const { paymentsByPersonStatus } = useGetPayments();

    const { products, funds, currencies, subscriptionStatuses, subscriptionIntervalUnits,
        staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { heyCentricUrl } = useAppSelector(state => state.payment);
    const { subscriptions, subscriptionsStatus, deleteSubscriptionsStatus } = useAppSelector(state => state.cardSubscription);

    const [filteredSubscriptions, setFilteredSubscriptions] = useState<Subscription[]>();
    const [cancelSubscriptionConfirmation, setCancelSubscriptionConfirmation] = useState(false);
    const [subscriptionToDelete, setSubscriptionToDelete] = useState<Subscription>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getCardSubscriptionAsync());
    }, [dispatch]);

    useEffect(() => {
        if (subscriptions && subscriptions?.length > 0 &&
            subscriptionStatuses && subscriptionStatuses?.length > 0) {
            const activeStatusId = subscriptionStatuses.find(y => y.code === SubscriptionStatusEnum.ACTIVE)?.id ?? '';
            setFilteredSubscriptions(subscriptions.filter(x => x.subscriptionStatusId === activeStatusId));
        }
    }, [subscriptions, subscriptionStatuses]);

    const deleteCardSubscription = (id: string | null) => {
        if (id !== null) {
            dispatch(deleteCardSubscriptionAsync(id)).then((response) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getCardSubscriptionAsync());
                    toast.success('Your cancellation request has been sent to our credit card processor and will be processed in due course.');
                }
            });
        }
    }

    if (staticDataStateStatus === ApiRequestStatus.Pending || paymentsByPersonStatus === ApiRequestStatus.Pending ||
        subscriptionsStatus === ApiRequestStatus.Pending || deleteSubscriptionsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>

                <Grid container rowSpacing={4}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Typography variant='h6'>Manage recurring payment & card details </Typography>
                    </Grid>

                    {filteredSubscriptions && filteredSubscriptions?.length > 0 &&
                        <>
                            <Grid item xs={4}>
                                <b>Subscription name</b>
                            </Grid>

                            <Grid item xs={2}>
                                <b>Expiry date</b>
                            </Grid>

                            <Grid item xs={2.5}><b>Recurrence period</b></Grid>

                            <Grid item xs={2}><b>Amount</b></Grid>

                            <Grid item xs={1.5}></Grid>

                            {filteredSubscriptions?.filter(x => x.active === true && x.subscriptionProducts.find(y => y.productId ===
                                products?.find(o => o.code === ProductEnum.FamAnnualMembership)?.id)).map((item: Subscription) => (
                                    <React.Fragment key={item.subscriptionId}>
                                        <Grid item xs={4} className="wordWrap">
                                            Fellow/Member annual membership subscription
                                        </Grid>

                                        <Grid item xs={2} className="wordWrap">
                                            {item.expiryDate && formatDateMonthName(new Date(item.expiryDate))}
                                        </Grid>

                                        <Grid item xs={2.5} className="wordWrap">Annual</Grid>

                                        <Grid item xs={2} className="wordWrap">Membership rate</Grid>

                                        <Grid item xs={1.5} className="wordWrap">
                                            {<Button variant="contained" color="primary"
                                                onClick={() => deleteCardSubscription(item.subscriptionId)}>
                                                Cancel
                                            </Button>}
                                        </Grid>
                                    </React.Fragment>
                                ))}

                            {filteredSubscriptions?.filter(x => x.active === true && x.subscriptionProducts.find(y => y.productId ===
                                products?.find(o => o.code === ProductEnum.Donation)?.id)).map((item: Subscription) => (
                                    <React.Fragment key={item.subscriptionId}>
                                        <Grid item xs={4} className="wordWrap">
                                            {funds?.find(x => x.id === (JSON.parse(item.subscriptionProducts[0].jsonValues ?? '') as PaymentProductJsonDto).FundId)?.value}
                                        </Grid>

                                        <Grid item xs={2} className="wordWrap">
                                            {item.expiryDate && formatDateMonthName(new Date(item.expiryDate))}
                                        </Grid>

                                        <Grid item xs={2.5} className="wordWrap">
                                            {subscriptionIntervalUnits?.find(x => x.id === item.subscriptionIntervalUnitId)?.value}
                                        </Grid>

                                        <Grid item xs={2} className="wordWrap">
                                            {item.subscriptionProducts[0].currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.EUR)?.id && CurrencyCodeIconEnum.EUR}
                                            {item.subscriptionProducts[0].currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.GBP)?.id && CurrencyCodeIconEnum.UK}
                                            {item.subscriptionProducts[0].currencyId === currencies?.find(x => x.code === CurrencyCodeEnum.USD)?.id && CurrencyCodeIconEnum.USA}
                                            {' '}
                                            {item.subscriptionProducts.reduce((sum, current) => sum + current.cost, 0)}
                                        </Grid>

                                        <Grid item xs={1.5} className="wordWrap">
                                            {<Button variant="contained" color="primary"
                                                onClick={() => {
                                                    setCancelSubscriptionConfirmation(true);
                                                    setSubscriptionToDelete(item);
                                                }}>
                                                Cancel
                                            </Button>}
                                        </Grid>
                                    </React.Fragment>
                                ))}
                        </>}

                    {filteredSubscriptions && filteredSubscriptions.length > 0 &&
                        <>
                            <Grid item xs={12}>
                                <Typography variant="body1" sx={{ color: 'primary.main' }}>
                                    <b>Note</b> - By clicking on the 'Edit card details' button below you will be directed to a 3<sup>rd</sup> party site.
                                    <b> Please login to this portal with your preferred email address.</b>
                                    <br />
                                    <i>
                                        (If you are unsure which email this is please check your preferred email address
                                        on <a href={`${String(process.env.REACT_APP_UPDATE_PROFILE_URI)}${window.location.search}`} style={{ color: 'primary.main' }}>your profile</a>.)
                                        Location of practice determines membership rate.
                                    </i>
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                {heyCentricUrl && <Button variant="contained" color="primary" href={heyCentricUrl}>
                                    <Link target="_blank" style={{ color: 'white', textDecoration: 'unset' }}
                                        to={{ pathname: heyCentricUrl }}>Edit card details</Link>
                                </Button>}
                            </Grid>

                            <Grid item xs={0} sm={8}></Grid>
                        </>
                    }

                    {(!filteredSubscriptions || filteredSubscriptions?.filter(x => x.active === true).length === 0) &&
                        <Grid item xs={12} className="wordWrap">
                            No recurring payments found.
                        </Grid>
                    }
                </Grid>
            </Box>

            {cancelSubscriptionConfirmation &&
                <UnsavedChangesDialog showDialog={true}
                    title={`Are you sure you want to delete - ${funds?.find(x => x.id ===
                        (JSON.parse(subscriptionToDelete?.subscriptionProducts[0].jsonValues ?? '') as PaymentProductJsonDto).FundId)?.value}?`}
                    subTitle={`You will loose the changes on clicking "YES".`}
                    yesButtonClick={() => {
                        subscriptionToDelete?.subscriptionId && deleteCardSubscription(subscriptionToDelete?.subscriptionId);
                        setCancelSubscriptionConfirmation(false);
                    }}
                    noButtonClick={() => setCancelSubscriptionConfirmation(false)}
                    showYesNoButtons={true} />}
        </>
    );
}

import { Box, Container, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import { useEffect } from "react";
import { Route, Switch } from "react-router";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import ServerError from "../errors/ServerError";
import NotFound from "../errors/NotFound";
import {
    AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal
} from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginRequest } from "../../authConfig";
import { useAppDispatch } from "../store/configureStore";
import Unauthorized from "../errors/Unauthorized";
import PrivateRoute from "./PrivateRoute";
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/system';
import './styles.css';
import MyReceiptsForm from "../../features/myReceipts/view/MyReceiptsForm";
import UpdateProfile from "../../features/updateProfile/view/UpdateProfile";
import MyDocumentsForm from "../../features/myDocuments/MyDocumentsForm";
import RequestForm from "../../features/request/RequestForm";
import EventApprovalForm from "../../features/pcsEventApproval/EventApprovalForm";
import MembershipTransferForm from "../../features/membershipTransfer/MembershipTransferForm";
import AffiliateMembershipForm from "../../features/affiliateAssociate/AffiliateMembershipForm";
import SummaryForm from "../../features/pcsSummary/SummaryForm";
import EnrolmentForm from "../../features/pcsEnrolment/EnrolmentForm";
import PcsPlanDashboard from "../../features/pcsPlan/view/dashboard/PcsPlanDashboard";
import PlanDetails from "../../features/pcsPlan/view/dashboard/PlanDetails";
import ConfirmReviewerDetails from "../../features/pcsPlan/view/planDetails/updatePlan/ConfirmReviewerDetails";
import Feedback from "../../features/pcsPlan/view/planDetails/updatePlan/Feedback";
import MembershipOnboardingForm from "../../features/membershipOnboarding/MembershipOnboardingForm";
import MemberInGoodStanding from "../../features/MemberInGoodStanding/MemberInGoodStanding";
import AlumniVolunteeringForm from "../../features/alumniVolunteering/AlumniVolunteeringForm";
import MembershipVolunteeringForm from "../../features/membershipVolunteering/MembershipVolunteeringForm";
import { AppPathEnum } from "../enums/appPathEnum";
import PaymentResponse from "../components/PaymentResponse";
import MembershipRenewForm from "../../features/membershipRenewal/MembershipRenewForm";
import Logout from "./Logout";
import RequestInterceptor from "../api/RequestInterceptor";
import DonationPaymentSuccessful from "../../features/donation/DonationPaymentSuccessful";
import ReductionsAndExemptions from "../../features/request/ReductionsAndExemptions";
import RequestPortalAccess from "../../features/request/RequestPortalAccess";
import Merchandise from "../../features/request/MerchandiseSpa/Merchandise";
import Linkedin from "../../features/request/Linkedin";
import PasTest from "../../features/request/PasTest";
import ClassNotes from "../../features/request/ClassNotes";
import Tours from "../../features/request/Tours";
import ContactClassmates from "../../features/request/ContactClassmates";
import Yearbook from "../../features/request/Yearbook";
import AccessCardAlumni from "../../features/request/AccessCardAlumni";
import AccessCardFellowAndMember from "../../features/request/AccessCardFellowAndMember";
import AbsenceFromPracticePcs from "../../features/request/AbsenceFromPracticePcs";
import RequestToWithdrawPcs from "../../features/request/RequestToWithdrawPcs";
import VolunteerRequest from "../../features/request/VolunteerRequest";
import ChangeOfName from "../../features/request/ChangeOfName";
import { isProductionMode, isValidUrl } from "../utils/util";
import DonationDashboard from "../../features/donation/DonationDashboard";
import DonationsForm from "../../features/donation/DonationsForm";
import DonationsDirectDebit from "../../features/donation/DonationsDirectDebit";
import MembershipDirectDebit from "../models/membership/MembershipDirectDebit";
import ManageDirectDebit from "../../features/myReceipts/view/ManageDirectDebit";
import { theme } from "./theam";
import ViewCpdForm from "../../features/pcsCpd/view/CpdDashboard/ViewCpdForm";
import ViewCpdDetails from "../../features/pcsCpd/view/ViewCpd/ViewCpdDetails";
import AddCpdForm from "../../features/pcsCpd/view/AddCpd/AddCpdForm";
import UpdateCpdForm from "../../features/pcsCpd/view/UpdateCpd/UpdateCpdForm";
import CloneCpdForm from "../../features/pcsCpd/view/CloneCpd/CloneCpdForm";
import ReEnrolmentForm from "../../features/pcsEnrolment/ReEnrolmentForm";
import PcsEnrolmentPaymentSucceed from "../../features/pcsEnrolment/PcsEnrolmentPaymentSucceed";
import PdpReviewerForm from "../../features/pcsPlanReview/dashboard/PdpReviewerForm";
import PlanReviewerDetails from "../../features/pcsPlanReview/dashboard/PlanReviewerDetails";
import PrepaidPaymentSucceed from "../../features/pcsEnrolment/PrepaidPaymentSucceed";

export const Pages = () => {
    const classes = useStyles();
    const isAuthenticated = useIsAuthenticated();
    const { instance, accounts, inProgress } = useMsal();
    const dispatch = useAppDispatch();

    if (inProgress === InteractionStatus.None && !isAuthenticated) {
        instance.loginRedirect(loginRequest);
    }

    useEffect(() => {
        if (accounts && inProgress === InteractionStatus.None) {
            const request = {
                ...loginRequest,
                account: accounts[0]
            };
            instance.acquireTokenSilent(request).then(() => {
                instance.enableAccountStorageEvents();
            });
        }
    }, [accounts, inProgress, instance, dispatch]);

    useEffect(() => {
        if (!isProductionMode()) {
            const metaRobots = document.createElement('meta');
            metaRobots.name = 'robots';
            metaRobots.content = 'noindex,nofollow';
            document.head.appendChild(metaRobots);
        }
    }, []);

    return (
        <>
            <ToastContainer position='top-center' hideProgressBar theme='colored' limit={1} />
            <AuthenticatedTemplate>
                <RequestInterceptor>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Container>
                            <Grid container className={classes.bannerCrest}>
                                <Box component="img" alt="RCSI"
                                    src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/inc-logo.svg`}></Box>
                            </Grid>
                            <Grid container>
                                <Box className={classes.bannerImg}>
                                    <BannerBackLink href={
                                        isValidUrl(String(new URLSearchParams(window.location.search).get("ref"))) === true
                                            ? String(new URLSearchParams(window.location.search).get("ref"))
                                            : String(process.env.REACT_APP_PORTAL_URI)}>&lt; Back to RCSI</BannerBackLink>
                                </Box>
                            </Grid>
                            <Switch>
                                <PrivateRoute path={AppPathEnum.ALUMNI_VOLUNTEERING} component={AlumniVolunteeringForm} />
                                <PrivateRoute path={AppPathEnum.DONATION_DASHBOARD} component={DonationDashboard} />
                                <PrivateRoute path={AppPathEnum.DONATION_REGION} component={DonationsForm} />
                                <PrivateRoute path={AppPathEnum.PCS_EVENT_APPROVAL} component={EventApprovalForm} />
                                <PrivateRoute path={AppPathEnum.PCS_PLAN_REVIEW} component={PdpReviewerForm} />
                                <PrivateRoute path={AppPathEnum.PCS_VIEW_CPDS} component={ViewCpdForm} />
                                <PrivateRoute path={AppPathEnum.PCS_VIEW_CPD_CREDIT_RECORD_ID} component={ViewCpdDetails} />
                                <PrivateRoute path={AppPathEnum.PCS_ADD_CPD} component={AddCpdForm} />
                                <PrivateRoute path={AppPathEnum.PCS_UPDATE_CPD_CREDIT_RECORD_ID} component={UpdateCpdForm} />
                                <PrivateRoute path={AppPathEnum.PCS_CLONE_CPD_CREDIT_RECORD_ID} component={CloneCpdForm} />
                                <PrivateRoute path={AppPathEnum.PCS_RE_ENROLL} component={ReEnrolmentForm} />
                                <PrivateRoute path={AppPathEnum.PCS_SUMMARY} component={SummaryForm} />
                                <PrivateRoute path={AppPathEnum.PCS_PLAN} component={PcsPlanDashboard} />
                                <PrivateRoute path={AppPathEnum.PCS_REVIEWER_DETAILS_ENROLMENT_YEAR_ID} component={ConfirmReviewerDetails} />
                                <PrivateRoute path={AppPathEnum.PCS_PLAN_DETAILS_ENROLMENT_YEAR_ID} component={PlanDetails} />
                                <PrivateRoute path={AppPathEnum.PCS_PLAN_REVIEWER_DETAILS_ENROLMENT_YEAR_ID} component={PlanReviewerDetails} />
                                <PrivateRoute path={AppPathEnum.PCS_PLAN_FEEDBACK_ENROLMENT_YEAR_ID} component={Feedback} />
                                <PrivateRoute path={AppPathEnum.PCS_ENROLMENT} component={EnrolmentForm} />
                                <PrivateRoute path={AppPathEnum.MEMBERSHIP_ONBOARDING} component={MembershipOnboardingForm} />
                                <PrivateRoute path={AppPathEnum.MEMBERSHIP_TRANSFER} component={MembershipTransferForm} />
                                <PrivateRoute path={AppPathEnum.AFFILIATE_ASSOCIATE} component={AffiliateMembershipForm} />
                                <PrivateRoute path={AppPathEnum.MEMBERSHIP_VOLUNTEERING} component={MembershipVolunteeringForm} />
                                <PrivateRoute path={AppPathEnum.MEMBERSHIP_RENEWAL} component={MembershipRenewForm} />
                                <PrivateRoute path={AppPathEnum.UPDATE_PROFILE} component={UpdateProfile} />
                                <PrivateRoute path={AppPathEnum.MY_RECEIPTS} component={MyReceiptsForm} />
                                <PrivateRoute path={AppPathEnum.MY_DOCUMENTS} component={MyDocumentsForm} />
                                <PrivateRoute path={AppPathEnum.REQUEST} component={RequestForm} />
                                <PrivateRoute path={AppPathEnum.MEMBER_IN_GOOD_STANDING} component={MemberInGoodStanding} />
                                <PrivateRoute path={AppPathEnum.RequestPortalAccess} component={RequestPortalAccess} />
                                <PrivateRoute path={AppPathEnum.Reductions_And_Exemptions} component={ReductionsAndExemptions} />
                                <PrivateRoute path={AppPathEnum.MERCHANDISE} component={Merchandise} />
                                <PrivateRoute path={AppPathEnum.LINKEDIN} component={Linkedin} />
                                <PrivateRoute path={AppPathEnum.PASTEST} component={PasTest} />
                                <PrivateRoute path={AppPathEnum.CLASS_NOTES} component={ClassNotes} />
                                <PrivateRoute path={AppPathEnum.TOURS} component={Tours} />
                                <PrivateRoute path={AppPathEnum.CONTACT_CLASSMATES} component={ContactClassmates} />
                                <PrivateRoute path={AppPathEnum.YEAR_BOOK} component={Yearbook} />
                                <PrivateRoute path={AppPathEnum.ACCESS_CARD_ALUMNI} component={AccessCardAlumni} />
                                <PrivateRoute path={AppPathEnum.ACCESS_CARD_FELLOW_AND_MEMBER} component={AccessCardFellowAndMember} />
                                <PrivateRoute path={AppPathEnum.ABSENCE_FROM_PRACTICE_PCS} component={AbsenceFromPracticePcs} />
                                <PrivateRoute path={AppPathEnum.REQUEST_TO_WITHDRAW_PCS} component={RequestToWithdrawPcs} />
                                <PrivateRoute path={AppPathEnum.VOLUNTEER_REQUEST} component={VolunteerRequest} />
                                <PrivateRoute path={AppPathEnum.CHANGE_OF_NAME} component={ChangeOfName} />
                                <PrivateRoute path={AppPathEnum.DONATIONS_DIRECT_DEBIT} component={DonationsDirectDebit} />
                                <PrivateRoute path={AppPathEnum.MEMBERSHIP_DIRECT_DEBIT} component={MembershipDirectDebit} />
                                <PrivateRoute path={AppPathEnum.MANAGE_DIRECT_DEBIT_BY_ID} component={ManageDirectDebit} />
                                <Route path={AppPathEnum.PREPAID_PAYMENT_SUCCESS_URI} component={PrepaidPaymentSucceed} />
                                <Route path={AppPathEnum.PAYMENT_SUCCESS_URI} component={PaymentResponse} />
                                <Route path={AppPathEnum.PCS_ENROLMENT_PAYMENT_SUCCEED} component={PcsEnrolmentPaymentSucceed} />
                                <Route path={AppPathEnum.DONATION_PAYMENT_SUCCESS_URI} component={DonationPaymentSuccessful} />
                                <Route path='/server-error/:errorMessage' component={ServerError} />
                                <Route path='/unauthorized' component={Unauthorized} />
                                <Route path="/logout" component={Logout} />
                                <Route component={NotFound} />
                            </Switch>
                            <Grid container className={classes.bannerFooterCopyright}>
                                <Grid item xs={12}>
                                    <p>&copy; RCSI</p>
                                </Grid>
                            </Grid>
                            <Grid container className={classes.bannerFooter}>
                                <Grid item md={9} sm={7} className={classes.bannerFooterText}>
                                    <p><strong>Royal College of Surgeons in Ireland</strong> Coláiste Ríoga na Máinleá in Éirinn</p>
                                </Grid>
                                <Grid item md={3} sm={5} className={classes.bannerFooterImg}>
                                    <Box component="img" alt="RCSI"
                                        src={`${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/footer/logos/RCSI-Royal_Logo_2019.svg`}
                                        sx={{ height: '135px', justifyContent: 'center' }}></Box>
                                </Grid>
                            </Grid>
                        </Container>
                    </ThemeProvider>
                </RequestInterceptor>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate></UnauthenticatedTemplate>
        </>
    )
}

const BannerBackLink = styled('a')({
    color: '#fff',
    fontWeight: '700',
    fontFamily: 'AvenirLTStd-Heavy,arial,sans-serif',
    display: 'inline-block',
    margin: '10px',
    padding: '10px 10px 10px 25px',
    textDecoration: 'none',
    "&:hover": {
        color: "#DFE3E8",
        textDecoration: 'underline',
    },
    "&:focus": {
        color: "#f3f3f3",
        textDecoration: 'underline',
    },
});

const useStyles = makeStyles({
    root: {
        "& .MuiPaper-root": {
            width: "auto",
            marginLeft: "0"
        }
    },
    "@global": {
        body: {
            fontFamily: 'AvenirLTStd-Heavy,arial,sans-serif'
        },
        ".MuiTypography-root": {
            fontFamily: 'AvenirLTStd-Heavy,arial,sans-serif'
        }
    },
    bannerImg: {
        width: '100%',
        height: '140px',
        backgroundImage: `url(${String(process.env.REACT_APP_STORAGE_ACCOUNT_URL)}/images/bg-banner-alt.jpg)`,
        backgroundPositionX: -300,
        backgroundRepeat: 'no-repeat',
        padding: '35px 0 0 15px'
    },
    bannerCrest: {
        background: "white",
        padding: '13px 24px 26px 48px'
    },
    bannerFooter: {
        background: "#de1834",
        padding: '13px 24px 13px 36px'
    },
    bannerFooterText: {
        color: 'white',
        padding: '40px 24px 26px 0px'
    },
    bannerFooterCopyright: {
        color: '#666',
        padding: '0px 24px 0px 36px'
    },
    bannerFooterImg: {
        padding: '20px 24px 26px 20px'
    }
})
import { Button, Grid, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import AppTextareaInput from "../../../app/components/AppTextareaInput";
import { declineReviewAsync, submitReviewAsync } from "../../../app/slices/pcsPdpSlice";
import { toast } from "react-toastify";
import pcsPlanFormModel from "../../pcsPlan/common/pcsPlanFormModel";
import { ReviewPcsPdpCommandDto } from "../../pcsPlan/models/reviewPcsPdpCommandDto";
import { AppPathEnum } from "../../../app/enums/appPathEnum";
import { useEffect, useState } from "react";
import UnsavedChangesDialog from "../../../app/components/UnsavedChangesDialog";

export default function ReviewerComments() {
    const { ReviewerComments } = pcsPlanFormModel.formField;
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();
    const { control, getValues, reset, trigger } = useFormContext();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { pcsEnrolmentYears, submitReviewStatus, declineReviewStatus } = useAppSelector(state => state.pcsPdp);

    useReferenceData();

    const [declineReviewConfirmation, setDeclineReviewConfirmation] = useState(false);

    useEffect(() => {
        if (pcsEnrolmentYears && pcsEnrolmentYears.length > 0) {
            const currentEnrolmentYear = pcsEnrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            reset({
                [ReviewerComments.name]: currentEnrolmentYear?.pdpReviewerComments
            });
        }
    }, [pcsEnrolmentYears, enrolmentYearId, reset]);

    const mapToReviewerCommentsDto = (isSubmitted: boolean) => {
        const data: ReviewPcsPdpCommandDto = {
            enrolmentYearId: enrolmentYearId,
            PdpReviewerComments: getValues([ReviewerComments.name]).toString(),
            submit: isSubmitted
        }
        return data;
    }

    const submitReviewerComments = async (isSubmitted: boolean) => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let reviewerCommentsDto = mapToReviewerCommentsDto(isSubmitted);
            dispatch(submitReviewAsync(reviewerCommentsDto)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    toast.success(MESSAGE_DETAILS_SAVED);
                    history.push(`${AppPathEnum.PCS_PLAN_REVIEW}${window.location.search}`);
                }
            });
        }
    };

    const declineReview = async () => {
        dispatch(declineReviewAsync(enrolmentYearId)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success("Review request declined.");
                history.push(`${AppPathEnum.PCS_PLAN_REVIEW}${window.location.search}`);
            }
        });
    };

    if (submitReviewStatus === ApiRequestStatus.Pending ||
        declineReviewStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
            <Typography variant='h5' sx={{ fontWeight: '550' }}>Reviewer comments</Typography>
        </Grid>

        <Grid item xs={12}>
            <AppTextareaInput control={control} name={ReviewerComments.name} label={ReviewerComments.label} />
        </Grid>

        <Grid item xs={12} sm={5} md={3} lg={2.5}>
            <Button variant="contained" color="warning" onClick={() => submitReviewerComments(false)}>
                Save as a Draft
            </Button>
        </Grid>

        <Grid item xs={12} sm={3} md={2} lg={1.5}>
            <Button variant="contained" color="success" onClick={() => submitReviewerComments(true)}>
                Submit
            </Button>
        </Grid>

        <Grid item xs={12} sm={4} md={3} lg={2.5}>
            <Button component={Link} to={`${AppPathEnum.PCS_PLAN_REVIEW}${window.location.search}`} variant="outlined">
                Cancel
            </Button>
        </Grid>

        <Grid item lg={2}></Grid>

        <Grid item xs={12} sm={12} md={4} lg={3.5}>
            <Button variant="contained" color="primary" onClick={() => setDeclineReviewConfirmation(true)}>
                Decline to review
            </Button>
        </Grid>

        <Grid item xs={12}></Grid>

        {declineReviewConfirmation &&
            <UnsavedChangesDialog showDialog={true}
                title={'Are you sure you want to decline to review?'}
                subTitle={`Clicking yes will disappear the record from reviewer list.`}
                yesButtonClick={() => {
                    declineReview();
                    setDeclineReviewConfirmation(false);
                }}
                noButtonClick={() => setDeclineReviewConfirmation(false)}
                showYesNoButtons={true} />}
    </>
}
import { Grid } from "@mui/material";
import AppInformationText from "./AppInformationText";

interface Props {
    label: string;
    value?: string;
    information_text?: string | React.ReactNode;
}

export default function AppLabelField(props: Props) {

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12} sm={3}>
                    <b>{props.label.trim().endsWith("*") ?
                        props.label.trim().substring(0, (props.label.trim().length - 1)) :
                        props.label}</b>
                </Grid>

                <Grid item xs={0} sm={1} sx={{ paddingTop: '20px !important' }}>
                    {props.information_text && props.information_text !== '' &&
                        <AppInformationText information_text={props.information_text} placement={'right'} />}
                </Grid>

                <Grid item xs={12} sm={7.5}>
                    {props.value}
                </Grid>

                <Grid item xs={12} sm={0.5} md={0.5} lg={0.5}></Grid>

            </Grid>
        </>
    )
}
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getActivitiesAcrossDomainsAsync } from '../slices/pcsPdpSlice';

export const useGetActivitiesAcrossDomains = (reload = false) => {
    const dispatch = useAppDispatch();
    const { activitiesAcrossDomains, getActivitiesAcrossDomainsStatus } = useAppSelector(state => state.pcsPdp);

    useEffect(() => {
        if ((!activitiesAcrossDomains || reload === true) && getActivitiesAcrossDomainsStatus === ApiRequestStatus.Idle) {
            dispatch(getActivitiesAcrossDomainsAsync());
        }
    }, [activitiesAcrossDomains, getActivitiesAcrossDomainsStatus, dispatch]);

    return { getActivitiesAcrossDomainsStatus };
};

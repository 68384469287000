import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { useReferenceData } from "../../app/customHooks/useReferenceData";
import { useAppDispatch, useAppSelector } from "../../app/store/configureStore";
import { ApiRequestStatus } from "../../app/enums/apiRequestStatus";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import AppAutocomplete from "../../app/components/AppAutocomplete";
import RequestFormModel from "./models/requestFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AbsenceFromPracticePcsValidationSchema } from "./models/requestValidationSchemas";
import { MapLeaveTypesToAutocompleteItems } from "../../app/utils/convertStaticDataToDropdownItems";
import AppDatePicker from "../../app/components/AppDatePicker";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import SuccessResponse from "./models/SuccessResponse";
import { formatDateWithMonthName } from "../../app/utils/util";

export default function AbsenceFromPracticePcs() {
    const {
        AbsenceFromPractice: {
            TypeOfLeave,
            AbsenceFromPracticeDateFrom,
            DateTo
        }
    } = RequestFormModel;

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(AbsenceFromPracticePcsValidationSchema)
    });
    const { control, trigger, reset, getValues, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const { leaveTypes, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    useReferenceData();

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    useEffect(() => {
        reset({
            [TypeOfLeave.name]: '',
            [AbsenceFromPracticeDateFrom.name]: '',
            [DateTo.name]: ''
        });
    }, [reset]);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.AbsenceFromPractice.toString(),
            details: `${TypeOfLeave.label.replace('*', '')}: ${leaveTypes?.find(x => x.id === formObj.TypeOfLeave)?.value}\n${AbsenceFromPracticeDateFrom.label.replace('*', '')}: ${formatDateWithMonthName(formObj.AbsenceFromPracticeDateFrom)}\n${DateTo.label.replace('*', '')}: ${formatDateWithMonthName(formObj.DateTo)}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = `Request sent successfully.`;

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Absence from Practice</Typography>
                </Grid>

                <Grid item xs={12}>
                    The Medical Council recognise periods of certified leave which is longer than
                    three months for the following reasons:
                    <br></br><br></br>
                    Maternity Leave / Sick Leave / Carers Leave / Parental Leave / Bereavement Leave /
                    Adoptive Leave / Acute complex emergencies*
                    <br></br><br></br>
                    <i>
                        *Acute complex emergencies are characterized by an active high-level armed
                        conflict, an acute environmental disaster such as a sudden 'shock' or short-term
                        disaster, a high level of poverty, and complex social and ethnic geography.
                    </i>
                    <br></br><br></br>
                    Please complete the below form and your absence will be recorded on your
                    annual Statement of Participation
                </Grid>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    <AppAutocomplete control={control} name={TypeOfLeave.name} label={TypeOfLeave.label}
                        items={MapLeaveTypesToAutocompleteItems(leaveTypes ?? [])} />
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker control={control} name={AbsenceFromPracticeDateFrom.name}
                        label={AbsenceFromPracticeDateFrom.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker control={control} name={DateTo.name} label={DateTo.label}
                        disabled={getValues(AbsenceFromPracticeDateFrom.name) === '' || getValues(AbsenceFromPracticeDateFrom.name) === undefined}
                        minDate={new Date(new Date(getValues(AbsenceFromPracticeDateFrom.name))
                            .setMonth(new Date(getValues(AbsenceFromPracticeDateFrom.name)).getMonth() + 3))} />
                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={12}>
                    Please note you may be required by the Medical Council to produce documentation
                    supporting your absence from practice if you are selected for a Maintenance of
                    Professional Competence Audit.
                </Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}
import { Box, Button, Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import AppLabelField from "../../../../app/components/AppLabelField";
import { PcsPlanStatuses } from "../../common/PcsPlanStatuses";
import pcsPlanFormModel from "../../common/pcsPlanFormModel";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { useAppSelector } from "../../../../app/store/configureStore";
import { useState, useEffect } from "react";
import { PcsPdpStatus } from "../../../../app/models/staticData/pcsPdpStatus";

export function PcsPlanStatus() {
    const { AnnualPlanStatus } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);

    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId));
        }
    }, [enrolmentYears, pcsPdpStatuses, enrolmentYearId]);

    return <Box sx={{ minWidth: "100%" }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12} sm={12}>
                <Button component={Link} to={`${AppPathEnum.PCS_PLAN}${window.location.search}`} variant="contained">
                    Back to Professional Development Plans
                </Button>
            </Grid>

            <Grid item xs={12} sx={{ marginTop: '2%' }}>
                <Typography variant='h4'>Professional Development Plan</Typography>
            </Grid>

            <Grid item xs={12}>
                <AppLabelField value={pcsPdpStatus?.value} label={AnnualPlanStatus.label}
                    information_text={pcsPdpStatus?.code && PcsPlanStatuses(pcsPdpStatus?.code)} />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='subtitle1'>
                    The aim of the Professional Development Plan is to enable you to:
                </Typography>

                <ol type="i">
                    <li>
                        <Typography variant='subtitle1'>
                            Plan your activities that will assist you in meeting your annual PCS requirements.
                        </Typography></li>
                    <li>
                        <Typography variant='subtitle1'>
                            Identify events, meetings, learning materials or discussion topics that will assist you.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle1'>
                            Review your completed PDP with practice colleagues.
                        </Typography>
                    </li>
                    <li>
                        <Typography variant='subtitle1'>
                            Reflect on your learning to-date and identify any areas of educational need for the following year?
                        </Typography>
                    </li>
                </ol>
            </Grid>

            <Grid item xs={12}></Grid>
        </Grid>
    </Box>
}

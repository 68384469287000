import { Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../app/store/configureStore";
import AppLabelField from "../../../app/components/AppLabelField";
import { useGetRevieweeRequests } from "../../../app/customHooks/useGetRevieweeRequests";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import pcsPlanFormModel from "../../pcsPlan/common/pcsPlanFormModel";
import { PcsEnrolmentYear } from "../../pcsPlan/models/pcsEnrolmentYear";

export function ViewPlannedActivities() {
    const { AccreditedContinuedEducationActivity, WorkBasedLearning, PracticeReview } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear>();

    const { pcsEnrolmentYears, getRevieweeRequestsStatus } = useAppSelector(state => state.pcsPdp);
    
    useReferenceData();
    useGetRevieweeRequests();

    useEffect(() => {
        if (pcsEnrolmentYears && pcsEnrolmentYears?.length > 0) {
            setEnrolmentYear(pcsEnrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [pcsEnrolmentYears, enrolmentYearId]);

    if (getRevieweeRequestsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Grid item xs={12}>
            <Typography variant='h5' sx={{ fontWeight: '550' }}>Planned activities</Typography>
        </Grid>

        <Grid item xs={12}>
            <AppLabelField label={AccreditedContinuedEducationActivity.label} value={enrolmentYear?.pdpExternal ? enrolmentYear.pdpExternal : ''} />
        </Grid>

        <Grid item xs={12}>
            <AppLabelField label={WorkBasedLearning.label} value={enrolmentYear?.pdpWorkBasedLearning ? enrolmentYear.pdpWorkBasedLearning : ''} />
        </Grid>

        <Grid item xs={12}>
            <AppLabelField label={PracticeReview.label} value={enrolmentYear?.pdpPracticeReview ? enrolmentYear.pdpPracticeReview : ''} />
        </Grid>

        <Grid item xs={12}></Grid>
    </>
}
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import RequestFormModel from "./models/requestFormModel";
import { ToursValidationSchema } from "./models/requestValidationSchemas";
import AppDatePicker from "../../app/components/AppDatePicker";
import AppTextInput from "../../app/components/AppTextInput";
import { addDays } from "../../app/utils/util";
import { useState } from "react";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function Tours() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(ToursValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        Tours: {
            NoOfAttendees,
            PreferredDate1,
            PreferredDate2,
            PreferredDate3
        }
    } = RequestFormModel;

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    let date2 = "";
    let date3 = "";
    if (formObj.PreferredDate2 != null && formObj.PreferredDate2 !== undefined)
        date2 = `${PreferredDate2.label}: ${formObj.PreferredDate2}`;
    if (formObj.PreferredDate3 != null && formObj.PreferredDate3 !== undefined)
        date3 = `${PreferredDate3.label}: ${formObj.PreferredDate3}`;


    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.RequestAlumniTour.toString(),
            details: `${NoOfAttendees.label.replace('*', '')}: ${formObj.NoOfAttendees}\n${PreferredDate1.label.replace('*', '')}: ${formObj.PreferredDate1}\r\n${date2}\r\n${date3}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = 'Your request has been queued, a member of the Alumni team will be in contact shortly.';

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [NoOfAttendees.name]: '',
                            [PreferredDate1.name]: '',
                            [PreferredDate2.name]: '',
                            [PreferredDate3.name]: ''
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Request alumni tour</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        RCSI porters provide campus tours on Tuesdays and Thursdays at 11am,
                        subject to availability. All tours must be booked and confirmed at
                        least 10 days in advance. Please indicate your preferred dates and
                        number of attendees below.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppTextInput control={control} name={NoOfAttendees.name} label={NoOfAttendees.label} />
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker
                        control={control}
                        name={PreferredDate1.name}
                        label={PreferredDate1.label}
                        minDate={addDays(new Date(), 0)}
                        maxDate={addDays(new Date(), 10)} />
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker
                        control={control}
                        name={PreferredDate2.name}
                        label={PreferredDate2.label}
                        minDate={addDays(new Date(), 0)}
                        maxDate={addDays(new Date(), 10)} />
                </Grid>

                <Grid item xs={12}>
                    <AppDatePicker
                        control={control}
                        name={PreferredDate3.name}
                        label={PreferredDate3.label}
                        minDate={addDays(new Date(), 0)}
                        maxDate={addDays(new Date(), 10)} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}
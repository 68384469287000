import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import RequestFormModel from "./models/requestFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import { RequestToWithdrawPcsValidationSchema } from "./models/requestValidationSchemas";
import SuccessResponse from "./models/SuccessResponse";
import { toast } from "react-toastify";
import { useAppDispatch } from "../../app/store/configureStore";
import { useState } from "react";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { submitRequestAsync } from "../../app/slices/requestSlice";

export default function RequestToWithdrawPcs() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(RequestToWithdrawPcsValidationSchema)
    });
    const { control, trigger, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        RequestToWithdraw: {
            Reason
        }
    } = RequestFormModel;

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.RequestToWithdraw.toString(),
            details: `${Reason.label.replace('*', '')}: ${formObj.Reason}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = `Request sent successfully.`;

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Request to withdraw</Typography>
                </Grid>

                <Grid item xs={12}>
                    If you wish to withdraw from the RCSI Professional Competence Scheme, please
                    <b> notify this office</b> by completing this form.
                    <br></br>
                    <br></br>
                    You might want to withdraw based on the following reasons:
                    <ul>
                        <li>You are no longer registered with the Medical Council so are not required to enrol in PCS.</li>
                        <li>You are now enrolled in a formal (Medical Council accredited) Training Programme.</li>
                        <li>You have changed specialty and are enrolling in a PCS scheme operated by a different Training body.</li>
                    </ul>
                    This will allow us update our records and ensure that you do not receive
                    unnecessary communication in relation to the PCS.
                </Grid>

                <Grid item xs={12}>

                </Grid>

                <Grid item xs={12}></Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <Typography variant="body1">
                        I wish to withdraw from the Professional Competence Scheme.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={Reason.name} label={Reason.label} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import { saveRevieweeCommentsAsync } from "../../../../../app/slices/pcsPdpSlice";
import { Link, useHistory, useParams } from "react-router-dom";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_PROCESSING_DETAILS } from "../../../../../app/utils/constant";
import { toast } from "react-toastify";
import { RevieweeCommentDto } from "../../../models/revieweeCommentDto";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";
import { getReferenceDataAsync } from "../../../../../app/slices/referenceDataSlice";
import { getPcsCurrentYearAsync } from "../../../../../app/slices/pcsSlice";
import { yupResolver } from "@hookform/resolvers/yup";
import { feedbackValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";

export default function Feedback() {
    const { ReviewerComments, MyReflection } = pcsPlanFormModel.formField;

    const { control, getValues, reset, trigger } = useForm({
        resolver: yupResolver(feedbackValidationSchema)
    });

    const [canAddComments, setCanAddComments] = useState<boolean>(false);
    const [currentPcsPdpStatus, setCurrentPcsPdpStatus] = useState<PcsPdpStatus>();
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();
    const history = useHistory();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);
    const { saveRevieweeCommentsStatus } = useAppSelector(state => state.pcsPdp);

    useEffect(() => {
        dispatch(getReferenceDataAsync());
        dispatch(getPcsEnrolmentYearsAsync());
        dispatch(getPcsCurrentYearAsync());
    }, [dispatch]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYears) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)
            reset({
                [ReviewerComments.name]: enrolmentYear?.pdpReviewerComments,
                [MyReflection.name]: enrolmentYear?.pdpRevieweeComments
            });

            const pdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);
            setCurrentPcsPdpStatus(pdpStatus);
            setCanAddComments(enrolmentYear?.year === currentYear && (pdpStatus?.code === PdpStatusEnum.Reviewed ||
                pdpStatus?.code === PdpStatusEnum.PdpReflectionComplete));
        }
    }, [pcsPdpStatuses, enrolmentYears, enrolmentYearId, currentYear, reset]);

    const addCommentsAsync = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let revieweeCommentDto: RevieweeCommentDto = {
                comments: getValues([MyReflection.name]).toString(),
                enrolmentYearId: enrolmentYearId
            };
            dispatch(saveRevieweeCommentsAsync(revieweeCommentDto)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                            toast.success(MESSAGE_DETAILS_SAVED);
                        }
                    });
                }
            });
        }
    };

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending
        || saveRevieweeCommentsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Box sx={{ width: '100%' }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <Typography variant='h5' sx={{ fontWeight: '550' }}>Feedback</Typography>
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={ReviewerComments.name} label={ReviewerComments.label} disabled />
                </Grid>

                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={MyReflection.name} label={MyReflection.label}
                        information_text_with_label={'Please note these reflections are not shared with your reviewer, they are for self-reflection purposes.'} />
                </Grid>

                <Grid item xs={12}></Grid>

                {canAddComments === true &&
                    <Grid item xs={12} sm={6} lg={3}>
                        <Button variant="contained" color="primary" onClick={() => addCommentsAsync()}>
                            {currentPcsPdpStatus?.code === PdpStatusEnum.PdpReflectionComplete ? 'Update' : 'Add'} my Reflections
                        </Button>
                    </Grid>
                }

                <Grid item xs={12} sm={6} lg={9}>
                    <Button component={Link} to={`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`}>
                        Cancel
                    </Button>
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </>
}
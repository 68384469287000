import { Link } from "react-router-dom";
import { updateProfileWithRefLink } from "../../../app/utils/util";

const RequestFormModel = {
  RequestPortalAccess: {
    IAm: {
      name: 'IAm',
      label: 'I am'
    },
    FurtherDetails: {
      name: 'FurtherDetails',
      label: 'Further details'
    }
  },
  ReductionsAndExemptions: {
    DiscountTypeOrExemption: {
      name: 'DiscountTypeOrExemption',
      label: 'Please select the type of discount or exemption you are requesting*',
      requiredErrorMsg: 'Please select the type of discount or exemption you are requesting.'
    },
    DateOfReturnFromParentalLeave: {
      name: 'DateOfReturnFromParentalLeave',
      label: 'If you are applying for the parental leave exemption, please provide a date of return (month or year)'
    },
    InfoForRequest: {
      name: 'InfoForRequest',
      label: 'Would you like to provide any additional information about your request?'
    }
  },
  Merchandise: {
    MerchandiseAreTheDetailsCorrect: {
      name: 'MerchandiseAreTheDetailsCorrect',
      label: <>
        I confirm my profile details are up-to-date <Link target={'_blank'} style={{ textDecoration: 'none' }}
          to={updateProfileWithRefLink()}>(please check my profile)</Link>.
      </>,
      labelText: 'I confirm my profile details are up-to-date.',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    },
    MerchandiseRequestDetails: {
      name: 'MerchandiseRequestDetails',
      label: 'Merchandise request details*',
      requiredErrorMsg: 'Merchandise request details is required.'
    }
  },
  LinkedIn: {
    LinkedInAreTheDetailsCorrect: {
      name: 'LinkedInAreTheDetailsCorrect',
      label: <>
        I wish to join the private RCSI Fellows and Members LinkedIn Group and confirm
        that I have updated <Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}>my profile</Link> with my LinkedIn account.
      </>,
      labelText: 'I wish to join the private RCSI Fellows and Members LinkedIn Group and confirm that I have updated my profile with my LinkedIn account.',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  PasTest: {
    PasTestAreTheDetailsCorrect: {
      name: 'PasTestAreTheDetailsCorrect',
      label: <>
        I wish to receive information regarding discounted access for PasTest and I confirm my
        profile details are up-to-date <Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}>(please check my profile)</Link>.
      </>,
      labelText: 'I wish to receive information regarding discounted access for PasTest and I confirm my profile details are up-to-date (please check my profile).',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  AcessCardFellowAndMember: {
    AcessCardFellowAndMemberAreTheDetailsCorrect: {
      name: 'AcessCardFellowAndMemberAreTheDetailsCorrect',
      label: <>
        I would like to request a campus access card and confirm my profile details are up-to-date (please check
        <Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}> my profile</Link>).
      </>,
      labelText: 'I would like to request a campus access card and confirm my profile details are up-to-date (please check my profile).',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  AcessCardAlumni: {
    AcessCardAlumniAreTheDetailsCorrect: {
      name: 'AcessCardAlumniAreTheDetailsCorrect',
      label: <>
        I would like to request a campus access card and confirm my profile details are up-to-date (please check
        <Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}> my profile</Link>).
      </>,
      labelText: 'I would like to request a campus access card and confirm my profile details are up-to-date (please check my profile).',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  ClassNotes: {
    PersonalProfessionalUpdates: {
      name: 'PersonalProfessionalUpdates',
      label: 'Personal/professional updates*',
      requiredErrorMsg: 'Personal/professional updates are required.'
    },
    ClassNotesAreTheDetailsCorrect: {
      name: 'ClassNotesAreTheDetailsCorrect',
      label: <>
        I confirm my profile details are up-to-date (please check
        <Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}> here</Link>).
      </>,
      labelText: 'I confirm my profile details are up-to-date.',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  ContactClassmates: {
    DetailsOfClassmate: {
      name: 'DetailsOfClassmate',
      label: 'Details of classmate*',
      requiredErrorMsg: 'Details of classmate is required.'
    },
    ContactClassmatesAreTheDetailsCorrect: {
      name: 'ContactClassmatesAreTheDetailsCorrect',
      label: <>
        I confirm my profile details are up-to-date (please check<Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}> here</Link>).
      </>,
      labelText: 'I confirm my profile details are up-to-date.',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  Yearbook: {
    YearbookAreTheDetailsCorrect: {
      name: 'YearbookAreTheDetailsCorrect',
      label: <>
        I would like to request a digital copy of my yearbook and confirm
        <Link target={'_blank'} style={{ textDecoration: 'none' }} to={updateProfileWithRefLink()}> my profile</Link> details are up-to-date.
      </>,
      labelText: 'I would like to request a digital copy of my yearbook and confirm my profile details are up-to-date.',
      requiredErrorMsg: 'Please confirm your details are up-to-date.'
    }
  },
  Tours: {
    NoOfAttendees: {
      name: 'NoOfAttendees',
      label: 'No. of attendees*',
      minValueErrorMsg: 'At least 1 attendee is required.',
      maxValueErrorMsg: 'Maximum 20 attendees are allowed.',
      requiredErrorMsg: 'No. of attendees is required.'
    },
    PreferredDate1: {
      name: 'PreferredDate1',
      label: 'Preferred date 1*',
      minErrorMsg: `Preferred date 1 should be within 10 days range from today`,
      maxErrorMsg: `Preferred date 1 should be within 10 days range from today`,
      requiredErrorMsg: 'Preferred date 1 is required.'
    },
    PreferredDate2: {
      name: 'PreferredDate2',
      label: 'Preferred date 2'
    },
    PreferredDate3: {
      name: 'PreferredDate3',
      label: 'Preferred date 3'
    }
  },
  VolunteerRequest: {
    CurrentRole: {
      name: 'CurrentRole',
      label: 'Current role (student or faculty)*',
      requiredErrorMsg: 'Current role (student or faculty) is required.'
    },
    DepartmentIfFacultyOrSocietyClubIfStudent: {
      name: 'DepartmentIfFacultyOrSocietyClubIfStudent',
      label: 'Department (if faculty) / Society or Club (if student)*',
      requiredErrorMsg: 'Department (if faculty) / Society or Club (if student) is required.'
    },
    TypeOfVolunteeringRequired: {
      name: 'TypeOfVolunteeringRequired',
      label: 'Type of volunteering required*',
      requiredErrorMsg: 'Type of volunteering is required.'
    },
    NumberOfVolunteersRequired: {
      name: 'NumberOfVolunteersRequired',
      label: 'How many volunteers are required?*',
      minErrorMsg: 'Number of volunteers must be a positive number.',
      typeErrorMsg: 'Number of volunteers must be a numeric value.',
      requiredErrorMsg: 'Number of volunteers are required.'
    },
    DateAndTimeOfVolunteering: {
      name: 'DateAndTimeOfVolunteering',
      label: 'Date and time of volunteering*',
      requiredErrorMsg: 'Date and time of volunteering is required.'
    },
    DurationOfVolunteeringInHours: {
      name: 'DurationOfVolunteeringInHours',
      label: 'Duration of volunteering (in hours)*',
      minErrorMsg: 'Duration of volunteering (in hours) must be a positive number.',
      typeErrorMsg: 'Duration of volunteering (in hours) must be a numeric value.',
      requiredErrorMsg: 'Duration of volunteering (in hours) is required.'
    },
    IsVolunteeringOnlineInPerson: {
      name: 'IsVolunteeringOnlineInPerson',
      label: 'Is volunteering online/in-person?*',
      requiredErrorMsg: 'Is volunteering online/in-person is required.'
    },
    Discipline: {
      name: 'Discipline',
      label: 'Discipline*',
      requiredErrorMsg: 'Discipline is required.'
    },
    AreaOfPractice: {
      name: 'AreaOfPractice',
      label: 'Area of practice*',
      requiredErrorMsg: 'Area of practice is required.'
    },
    CareerRange: {
      name: 'CareerRange',
      label: 'Career range*',
      requiredErrorMsg: 'Career range is required.'
    },
    Location: {
      name: 'Location',
      label: 'Location*',
      requiredErrorMsg: 'Location is required.'
    },
    WhatAreYouAskingThemToDoDeliverOn: {
      name: 'WhatAreYouAskingThemToDoDeliverOn',
      label: 'What are you asking them to do/deliver on?'
    },
    AnyAdditonalInfo: {
      name: 'AnyAdditonalInfo',
      label: 'Any additional information?'
    },
    ReferenceForVolunteringJob: {
      name: 'ReferenceForVolunteringJob',
      label: 'Do you have a specific individual(s) in mind for this volunteering ask? If so, please provide the name and class year of the individual(s).'
    }
  },
  ChangeOfName: {
    UpdatedName: {
      name: 'UpdatedName',
      label: 'Updated name*',
      requiredErrorMsg: 'Updated name is required.'
    }
  },
  RequestToWithdraw: {
    Reason: {
      name: 'Reason',
      label: 'Reason*',
      requiredErrorMsg: 'Reason is required.'
    }
  },
  AbsenceFromPractice: {
    TypeOfLeave: {
      name: 'TypeOfLeave',
      label: 'Type of leave*',
      requiredErrorMsg: 'Type of leave is required.'
    },
    AbsenceFromPracticeDateFrom: {
      name: 'AbsenceFromPracticeDateFrom',
      label: 'Date from*',
      requiredErrorMsg: 'Date from is required.'
    },
    DateTo: {
      name: 'DateTo',
      label: 'Date to*',
      requiredErrorMsg: 'Date to is required.'
    }
  }
};

export default RequestFormModel;

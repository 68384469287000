import { Box, Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { PcsPdpStatus } from "../../../app/models/staticData/pcsPdpStatus";
import { useAppSelector } from "../../../app/store/configureStore";
import pcsPlanFormModel from "../../pcsPlan/common/pcsPlanFormModel";
import { PcsPlanStatuses } from "../../pcsPlan/common/PcsPlanStatuses";
import AppInformationText from "../../../app/components/AppInformationText";

export function PcsPlanStatus() {
    const { AnnualPlanStatus } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { pcsEnrolmentYears } = useAppSelector(state => state.pcsPdp);

    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    useEffect(() => {
        if (pcsEnrolmentYears && pcsEnrolmentYears?.length > 0 &&
            pcsPdpStatuses && pcsPdpStatuses.length > 0) {
            const enrolmentYear = pcsEnrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId));
        }
    }, [pcsEnrolmentYears, pcsPdpStatuses, enrolmentYearId]);

    return <Box sx={{ minWidth: "100%" }}>
        <Grid container rowSpacing={4}>
            
            <Grid item xs={12}></Grid>
            <Grid item xs={12}></Grid>

            <Grid item xs={5} sm={5} lg={4}>
                <b>{AnnualPlanStatus.label.replace("*", "")}</b>
            </Grid>

            <Grid item xs={2} sm={1} lg={1} sx={{ marginTop: '-11px' }}>
                <AppInformationText information_text={pcsPdpStatus?.code && PcsPlanStatuses(pcsPdpStatus?.code)} />
            </Grid>

            <Grid item xs={5} sm={5} lg={7}>
                {pcsPdpStatus?.value}
            </Grid>
        </Grid>
    </Box>
}

import { Button, Grid } from "@mui/material";
import { FileDto } from "../../app/models/membership/fileDto";
import { formatBytes, formatDateWithTime } from "../../app/utils/util";
import DownloadIcon from '@mui/icons-material/Download';
import { famTransferDownloadFileAsync } from "../../app/slices/famTransferSlice";
import { useAppDispatch } from "../../app/store/configureStore";

interface Props {
    fileDocument: FileDto | undefined;
}

export function FileDocument({ fileDocument }: Props) {

    const dispatch = useAppDispatch();

    const downloadFile = (fileName: string | null) => {
        if (fileName !== null) {
            dispatch(famTransferDownloadFileAsync(fileName)).then((response: any) => {
                const url = window.URL.createObjectURL(new Blob([response.payload], { type: 'application/octet-stream' }));
                const link = document.createElement('a');

                link.href = url;
                link.download = response.meta.arg;

                document.body.appendChild(link);

                link.click();
                link.remove();

                window.URL.revokeObjectURL(url);
            });
        }
    }

    return <>

        <Grid item xs={12}>
            <b>Current file :</b>
        </Grid>

        <Grid item xs={12} sx={{ wordWrap: 'break-word' }}>
            {fileDocument && fileDocument?.name}
        </Grid>

        <Grid item xs={12}>
            <b>Size:</b> {fileDocument?.size && fileDocument?.size !== null && formatBytes(fileDocument?.size)}
        </Grid>

        <Grid item xs={12}>
            <b>Last modified:</b> {fileDocument?.modifiedDate && fileDocument?.modifiedDate !== null &&
                formatDateWithTime(new Date(fileDocument?.modifiedDate))}
        </Grid>

        <Grid item xs={12}>
            <Button sx={{ "& .MuiButton-startIcon": { marginRight: "0px" } }} variant="outlined" startIcon={<DownloadIcon />}
                onClick={() => fileDocument?.name && downloadFile(fileDocument?.name)} >
                    Download
            </Button>
        </Grid>
    </>
}

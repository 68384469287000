import * as yup from 'yup';
import "yup-phone-lite";
import RequestFormModel from './requestFormModel';
import { addDays } from '../../../app/utils/util';
import { MESSAGE_NOT_A_VALUE } from '../../../app/utils/constant';

const {
    AcessCardAlumni: {
        AcessCardAlumniAreTheDetailsCorrect
    },
    AcessCardFellowAndMember: {
        AcessCardFellowAndMemberAreTheDetailsCorrect
    },
    ReductionsAndExemptions: {
        DiscountTypeOrExemption
    },
    Merchandise: {
        MerchandiseAreTheDetailsCorrect,
        MerchandiseRequestDetails
    },
    LinkedIn: {
        LinkedInAreTheDetailsCorrect
    },
    PasTest: {
        PasTestAreTheDetailsCorrect
    },
    ClassNotes: {
        ClassNotesAreTheDetailsCorrect,
        PersonalProfessionalUpdates
    },
    ContactClassmates: {
        DetailsOfClassmate,
        ContactClassmatesAreTheDetailsCorrect
    },
    Yearbook: {
        YearbookAreTheDetailsCorrect
    },
    Tours: {
        NoOfAttendees,
        PreferredDate1
    },
    VolunteerRequest: {
        CurrentRole,
        DepartmentIfFacultyOrSocietyClubIfStudent,
        TypeOfVolunteeringRequired,
        NumberOfVolunteersRequired,
        DateAndTimeOfVolunteering,
        DurationOfVolunteeringInHours,
        IsVolunteeringOnlineInPerson,
        Discipline,
        AreaOfPractice,
        CareerRange,
        Location
    },
    ChangeOfName: {
        UpdatedName
    },
    RequestToWithdraw: {
        Reason
    },
    AbsenceFromPractice: {
        TypeOfLeave,
        AbsenceFromPracticeDateFrom,
        DateTo
    }
} = RequestFormModel;

export const ReductionsAndExemptionsValidationSchema = yup.object().shape({
    [DiscountTypeOrExemption.name]: yup.string().required(`${DiscountTypeOrExemption.requiredErrorMsg}`)
});

export const MerchandiseValidationSchema = yup.object().shape({
    [MerchandiseRequestDetails.name]: yup.string().required(`${MerchandiseRequestDetails.requiredErrorMsg}`),
    [MerchandiseAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], MerchandiseAreTheDetailsCorrect.requiredErrorMsg)
});

export const LinkedinValidationSchema = yup.object().shape({
    [LinkedInAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], `${LinkedInAreTheDetailsCorrect.requiredErrorMsg}`)
});

export const PastestValidationSchema = yup.object().shape({
    [PasTestAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], `${PasTestAreTheDetailsCorrect.requiredErrorMsg}`)
});

export const ClassNotesValidationSchema = yup.object().shape({
    [PersonalProfessionalUpdates.name]: yup.string().required(`${PersonalProfessionalUpdates.requiredErrorMsg}`),
    [ClassNotesAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], ClassNotesAreTheDetailsCorrect.requiredErrorMsg)
});

export const ContactClassmateValidationSchema = yup.object().shape({
    [DetailsOfClassmate.name]: yup.string().required(`${DetailsOfClassmate.requiredErrorMsg}`),
    [ContactClassmatesAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], ContactClassmatesAreTheDetailsCorrect.requiredErrorMsg)
});

export const YearbookValidationSchema = yup.object().shape({
    [YearbookAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], YearbookAreTheDetailsCorrect.requiredErrorMsg)
});

export const AcessCardAlumniValidationSchema = yup.object().shape({
    [AcessCardAlumniAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], AcessCardAlumniAreTheDetailsCorrect.requiredErrorMsg)
});

export const AcessCardFellowAndMemberValidationSchema = yup.object().shape({
    [AcessCardFellowAndMemberAreTheDetailsCorrect.name]: yup.boolean().oneOf([true], AcessCardFellowAndMemberAreTheDetailsCorrect.requiredErrorMsg)
});

export const ToursValidationSchema = yup.object().shape({
    [NoOfAttendees.name]: yup.number()
        .min(1, `${NoOfAttendees.minValueErrorMsg}`)
        .max(20, `${NoOfAttendees.maxValueErrorMsg}`)
        .required(`${NoOfAttendees.requiredErrorMsg}`)
        .typeError(`${NoOfAttendees.requiredErrorMsg}`),
    [PreferredDate1.name]: yup.date()
        .max(addDays(new Date(), 10), PreferredDate1.maxErrorMsg)
        .min(addDays(new Date(), 0), PreferredDate1.minErrorMsg)
        .required(PreferredDate1.requiredErrorMsg)
        .typeError(PreferredDate1.requiredErrorMsg)
});

export const VolunteerRequestValidationSchema = yup.object().shape({
    [CurrentRole.name]: yup.string().required(CurrentRole.requiredErrorMsg),
    [DepartmentIfFacultyOrSocietyClubIfStudent.name]: yup.string().required(DepartmentIfFacultyOrSocietyClubIfStudent.requiredErrorMsg),
    [TypeOfVolunteeringRequired.name]: yup.string().required(TypeOfVolunteeringRequired.requiredErrorMsg),
    [NumberOfVolunteersRequired.name]: yup.number().min(0, NumberOfVolunteersRequired.minErrorMsg)
        .typeError(NumberOfVolunteersRequired.typeErrorMsg).required(NumberOfVolunteersRequired.requiredErrorMsg),
    [DateAndTimeOfVolunteering.name]: yup.string().required(DateAndTimeOfVolunteering.requiredErrorMsg),
    [DurationOfVolunteeringInHours.name]: yup.number().min(0.1, DurationOfVolunteeringInHours.minErrorMsg)
        .typeError(DurationOfVolunteeringInHours.typeErrorMsg)
        .required(DurationOfVolunteeringInHours.requiredErrorMsg),
    [IsVolunteeringOnlineInPerson.name]: yup.string().required(IsVolunteeringOnlineInPerson.requiredErrorMsg),
    [Discipline.name]: yup.string().required(Discipline.requiredErrorMsg),
    [AreaOfPractice.name]: yup.string().when([Discipline.name],
        ([discipline], schema) => {
            return schema.test(`${AreaOfPractice.name}`,
                `${AreaOfPractice.requiredErrorMsg}`,
                function (areaOfPractice) {
                    if (discipline === "" && areaOfPractice === MESSAGE_NOT_A_VALUE) {
                        return true;
                    }
                    if (discipline !== "" && (areaOfPractice === "" || areaOfPractice === MESSAGE_NOT_A_VALUE)) {
                        return false;
                    }
                    return true;
                });
        }),
    [CareerRange.name]: yup.string().required(CareerRange.requiredErrorMsg),
    [Location.name]: yup.string().required(Location.requiredErrorMsg)
});

export const ChangeOfNameValidationSchema = yup.object().shape({
    [UpdatedName.name]: yup.string().required(`${UpdatedName.requiredErrorMsg}`)
});

export const RequestToWithdrawPcsValidationSchema = yup.object().shape({
    [Reason.name]: yup.string().required(`${Reason.requiredErrorMsg}`)
});

export const AbsenceFromPracticePcsValidationSchema = yup.object().shape({
    [TypeOfLeave.name]: yup.string().required(`${TypeOfLeave.requiredErrorMsg}`),
    [AbsenceFromPracticeDateFrom.name]: yup.string().required(`${AbsenceFromPracticeDateFrom.requiredErrorMsg}`),
    [DateTo.name]: yup.string().required(`${DateTo.requiredErrorMsg}`)
});

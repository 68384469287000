import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/configureStore';
import { ApiRequestStatus } from '../enums/apiRequestStatus';
import { getRevieweeRequestsAsync } from '../slices/pcsPdpSlice';

export const useGetRevieweeRequests = () => {
    const dispatch = useAppDispatch();
    const { pcsEnrolmentYears, getRevieweeRequestsStatus } = useAppSelector(state => state.pcsPdp);

    useEffect(() => {
        if (pcsEnrolmentYears === null &&
            getRevieweeRequestsStatus === ApiRequestStatus.Idle) {
            dispatch(getRevieweeRequestsAsync());
        }
    }, [pcsEnrolmentYears, getRevieweeRequestsStatus, dispatch]);

    return { getRevieweeRequestsStatus };
};

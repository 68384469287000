import { Box, Button, Grid, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../../app/store/configureStore";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { useState, useEffect } from "react";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";

export function ViewFeedback() {
    const { ReviewerComments, MyReflection } = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear } = useAppSelector(state => state.pcs);

    const [canAddComments, setCanAddComments] = useState<boolean>(false);
    const [currentPcsPdpStatus, setCurrentPcsPdpStatus] = useState<PcsPdpStatus>();

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYears) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)
            const pdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);
            setCurrentPcsPdpStatus(pdpStatus);
            setCanAddComments(enrolmentYear?.year === currentYear && (pdpStatus?.code === PdpStatusEnum.Reviewed ||
                pdpStatus?.code === PdpStatusEnum.PdpReflectionComplete));
        }
    }, [pcsPdpStatuses, currentYear, enrolmentYearId, enrolmentYears]);

    return <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Typography variant='h5' sx={{ fontWeight: '550' }}>Feedback</Typography>
            </Grid>

            <Grid item xs={4}>
                <b>{ReviewerComments.label.replace("*", "")}</b>
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={4}>
                {enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)?.pdpReviewerComments ?? ''}
            </Grid>

            <Grid item xs={3.5}></Grid>

            <Grid item xs={4}>
                <b>{MyReflection.label.replace("*", "")}</b>
            </Grid>

            <Grid item xs={0.5}></Grid>

            <Grid item xs={4}>
                {enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId)?.pdpRevieweeComments ?? ''}
            </Grid>

            <Grid item xs={3.5}></Grid>

            <Grid item xs={12}></Grid>

            {canAddComments === true &&
                <Grid item xs={12} sm={12}>
                    <Button variant="contained" color="primary"
                        component={Link} to={`${AppPathEnum.PCS_PLAN_FEEDBACK_ENROLMENT}/${enrolmentYearId}${window.location.search}`}>
                        {currentPcsPdpStatus?.code === PdpStatusEnum.PdpReflectionComplete ? 'Update' : 'Add'} my Reflections
                    </Button>
                </Grid>
            }

        </Grid>
    </Box>
}
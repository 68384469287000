import { useMsal } from "@azure/msal-react";
import { Box, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useGetWorkpostList } from "../../../../app/customHooks/useGetWorkpostList";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { IdTokenClaims } from "../../../../app/models/account/idTokenClaims";
import registrationFormModel from "../../../../app/models/pcsEnrolment/registrationFormModel";
import { WorkPostDto } from "../../../../app/models/workpost/workPostDto";
import { useAppSelector, useAppDispatch } from "../../../../app/store/configureStore";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { formatDate, pcsFilter, updateProfileWithRefLink } from "../../../../app/utils/util";
import { Link } from "react-router-dom";

export function WorkDetails() {
  const {
    workDetailsFormField: {
      StartDate,
      PostLevel,
      Country,
      NameOfClinicalSupervisor,
      NameOfEmployer,
      OtherNameOfEmployer,
      JobTitle
    }
  } = registrationFormModel;

  const methods = useFormContext();
  const { reset } = methods;

  const { instance } = useMsal();
  const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

  const { countries, workPostLevels, hospitals } = useAppSelector(state => state.ReferenceData);
  const { workpostList, workpostListStatus } = useAppSelector(state => state.workpost);

  const [latestWorkpost, setLatestWorkpost] = useState<WorkPostDto>();

  useGetWorkpostList();

  useEffect(() => {
    if (workpostList && workpostList.length > 0) {
      setLatestWorkpost(workpostList?.slice().sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime())[0]);
    }
  }, [workpostList]);

  useEffect(() => {
    reset({
      CurrentUserRoles: userRoles
    });
  }, []);

  if (workpostListStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  return <Box sx={{
    width: '96%', backgroundColor: '#e7e7e7', padding: '25px',
    paddingRight: '0px', marginTop: '10px'
  }}>
    <Grid container rowSpacing={4}>

      <Grid item xs={12}>
        <Typography variant='h5' sx={{ fontWeight: '550' }}>Current employment details</Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant='subtitle2'>
          To Update work details please go to <Link target={'_blank'} style={{ textDecoration: 'none' }}
            to={updateProfileWithRefLink()}>my profile</Link> on the portal
        </Typography>
      </Grid>

      <Grid item xs={4}>
        <b>{StartDate.label.replace("*", "")}</b>
      </Grid>

      <Grid item xs={0.5}></Grid>

      <Grid item xs={4}>
        {latestWorkpost?.startDate && formatDate(new Date(latestWorkpost?.startDate))}
      </Grid>

      <Grid item xs={3.5}></Grid>

      {userRoles && pcsFilter(userRoles) && <>
        <Grid item xs={4}>
          <b>{PostLevel.label.replace("*", "")}</b>
        </Grid>

        <Grid item xs={0.5}></Grid>

        <Grid item xs={4}>
          {workPostLevels?.find(x => x.id === latestWorkpost?.workPostLevelId)?.value}
        </Grid>

        <Grid item xs={3.5}></Grid>
      </>}

      <Grid item xs={4}>
        <b>{JobTitle.label.replace("*", "")}</b>
      </Grid>

      <Grid item xs={0.5}></Grid>

      <Grid item xs={4}>
        {latestWorkpost?.jobTitle}
      </Grid>

      <Grid item xs={3.5}></Grid>

      <Grid item xs={4}>
        <b>{Country.label.replace("*", "")}</b>
      </Grid>

      <Grid item xs={0.5}></Grid>

      <Grid item xs={4}>
        {countries && countries?.find(x => x.id === latestWorkpost?.countryId)?.value}
      </Grid>

      <Grid item xs={3.5}></Grid>

      {userRoles && pcsFilter(userRoles) &&
        <>
          <Grid item xs={4}>
            <b>{NameOfClinicalSupervisor.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {latestWorkpost?.clinicalSupervisor}
          </Grid>

          <Grid item xs={3.5}></Grid>
        </>
      }

      {latestWorkpost?.employerId !== undefined && latestWorkpost?.employerId !== null &&
        latestWorkpost?.employerId !== "" && <>
          <Grid item xs={4}>
            <b>{NameOfEmployer.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {hospitals?.find(x => x.id === latestWorkpost?.employerId)?.value}
          </Grid>

          <Grid item xs={3.5}></Grid>
        </>}

      {latestWorkpost?.employerOther !== undefined && latestWorkpost?.employerOther !== null &&
        latestWorkpost?.employerOther !== "" && <>
          <Grid item xs={4}>
            <b>{OtherNameOfEmployer.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {latestWorkpost?.employerOther}
          </Grid>

          <Grid item xs={3.5}></Grid>
        </>}
    </Grid>
  </Box>
}

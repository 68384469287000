export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_SIGNIN_POLICY,
        forgotPassword: process.env.REACT_APP_PASSWORDRESET_POLICY,
        editProfile: process.env.REACT_APP_PASSWORDCHANGE_POLICY
    },
    authorities: {
        signUpSignIn: {
            authority: `${process.env.REACT_APP_AUTHORITY}${process.env.REACT_APP_SIGNIN_POLICY}`,
            scopes: ["openid", "profile", String(process.env.REACT_APP_SCOPES)]
        },
        forgotPassword: {
            authority: `${process.env.REACT_APP_AUTHORITY}${process.env.REACT_APP_PASSWORDRESET_POLICY}`,
            scopes: ["openid", "profile", String(process.env.REACT_APP_SCOPES)]
        },
        editProfile: {
            authority: `${process.env.REACT_APP_AUTHORITY}${process.env.REACT_APP_PASSWORDCHANGE_POLICY}`,
            scopes: ["openid", "profile", String(process.env.REACT_APP_SCOPES)]
        }
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN
}

export const msalConfig = {
    auth: {
        clientId: String(process.env.REACT_APP_CLIENT_ID),
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [String(b2cPolicies.authorityDomain)],
        redirectUri: String(process.env.REACT_APP_REDIRECT_URI),
        postLogoutRedirectUri: String(process.env.REACT_APP_PORTAL_LOGOUT_URI)
    },
    cache: {
        cacheLocation: "sessionStorage"
    },
    system: {
        allowRedirectInIframe: false
    }
};

export const loginRequest = {
    scopes: [...b2cPolicies.authorities.signUpSignIn.scopes]
};

export const tokenRequest = {
    scopes: b2cPolicies.authorities.signUpSignIn.scopes,
    forceRefresh: false
};

export const silentRequest = {
    scopes: ["openid", "profile"],
};
import { Box, Button, Grid, Typography } from "@mui/material";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { useEffect, useState } from "react";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { cancelReviewRequestAsync, searchReviewerByPersonIdAsync } from "../../../../../app/slices/pcsPdpSlice";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, REVIEW_REQUEST_CANCELLED } from "../../../../../app/utils/constant";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";
import { useGetPcsEnrolmentYears } from "../../../../../app/customHooks/useGetPcsEnrolmentYears";
import { useParams } from "react-router-dom";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { toast } from "react-toastify";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";

export function ViewReviewer() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { Title, FirstName, Surname } = pcsPlanFormModel.formField;

    const { titles, pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { reviewerDetails, searchReviewerByPersonIdStatus, cancelReviewRequestStatus } = useAppSelector(state => state.pcsPdp);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear | undefined>();
    const [canCancelReviewRequest, setCanCancelReviewRequest] = useState<boolean>();

    const dispatch = useAppDispatch();

    useGetPcsEnrolmentYears();

    useEffect(() => {
        if (enrolmentYear && enrolmentYear !== null && reviewerDetails === null &&
            enrolmentYear?.pdpReviewerPersonId !== null) {
            dispatch(searchReviewerByPersonIdAsync(enrolmentYear?.pdpReviewerPersonId));
        }
    }, [enrolmentYear, reviewerDetails, dispatch]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            setEnrolmentYear(enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [enrolmentYears, enrolmentYearId]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanCancelReviewRequest((pcsPdpStatus?.code === PdpStatusEnum.UnderReview
                || pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestPending) && enrolmentYear?.year === currentYear);
        }
    }, [enrolmentYears, pcsPdpStatuses, enrolmentYearId, currentYear]);

    const cancelReviewRequest = () => {
        dispatch(cancelReviewRequestAsync(enrolmentYearId)).then((response: any) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                    if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        toast.success(REVIEW_REQUEST_CANCELLED);
                    }
                });
            }
        });;
    }

    if (cancelReviewRequestStatus === ApiRequestStatus.Pending
        || searchReviewerByPersonIdStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={4}>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Typography variant='h6'><b>Reviewer details</b></Typography>
            </Grid>

            <Grid item xs={12}>
                <AppLabelField value={titles?.find(x => x.id === reviewerDetails?.titleId)?.value} label={Title.label} />
            </Grid>

            <Grid item xs={12}>
                <AppLabelField value={reviewerDetails?.firstnames} label={FirstName.label} />
            </Grid>

            <Grid item xs={12}>
                <AppLabelField value={reviewerDetails?.surname} label={Surname.label} />
            </Grid>

            <Grid item xs={12}></Grid>

            {canCancelReviewRequest === true &&
                <Grid item xs={4} sm={12}>
                    <Button variant="contained" color="primary" onClick={() => cancelReviewRequest()}>
                        Cancel review request
                    </Button>
                </Grid>
            }

        </Grid>
    </Box>
}

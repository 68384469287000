const pcsPlanFormModel = {
    formId: 'PcaAnnualPlanForm',
    formField: {
      StartDate: {
        name: 'StartDate',
        label: 'Start date'
      },
      PostLevel: {
        name: 'PostLevel',
        label: 'Post level'
      },
      NameOfEmployer: {
        name: 'NameOfEmployer',
        label: 'Name of employer'
      },
      AnnualPlanStatus: {
        name: 'AnnualPlanStatus',
        label: 'Annual plan status'
      },
      AccreditedContinuedEducationActivity: {
        name: 'AccreditedContinuedEducationActivity',
        label: 'Accredited Continued Education (CE) Activity*',
        requiredErrorMsg: 'Accredited Continued Education (CE) Activity is required'
      },
      WorkBasedLearning: {
        name: 'WorkBasedLearning',
        label: 'Work based learning*',
        requiredErrorMsg: 'Work based learning is required'
      },
      PracticeReview: {
        name: 'PracticeReview',
        label: 'Practice review*',
        requiredErrorMsg: 'Practice review is required'
      },
      EngagedInPracticeOfMedicine: {
        name: 'engagedInPracticeOfMedicine'
      },
      FirstName: {
        name: 'FirstName',
        label: 'First name*',
        requiredErrorMsg: 'First name is required'
      },
      Surname: {
        name: 'Surname',
        label: 'Surname*',
        requiredErrorMsg: 'Surname is required'
      },
      Name: {
        name: 'Name',
        label: 'Name'
      },
      Title: {
        name: 'Title',
        label: 'Title*',
        requiredErrorMsg: 'Title is required'
      },
      IMCNumber: {
        name: 'IMCNumber',
        label: 'IMC number*',
        requiredErrorMsg: 'IMC number is required.',
        typeError: 'IMC number must be a number.',
        minErrorMessage: 'IMC number must be greater than 0.',
        maxErrorMessage: 'IMC number must be 6 digits or less in length.'
      },
      EmailAddress: {
        name: 'EmailAddress',
        label: 'Email address',
        typeError: 'Email address is not valid'
      },
      ReviewerComments: {
        name: 'ReviewerComments',
        label: 'Reviewer feedback'
      },
      MyReflection: {
        name: 'MyReflection',
        label: 'My reflection*',
        requiredErrorMsg: 'Reflection is required'
      }
    }
  };
  
  export default pcsPlanFormModel;
import { Grid, Typography } from "@mui/material";
import { useAppSelector } from "../../../app/store/configureStore";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import AppLabelField from "../../../app/components/AppLabelField";
import pcsPlanFormModel from "../../pcsPlan/common/pcsPlanFormModel";

export function ViewReviewerDetails() {

    const { Title, Name, IMCNumber } = pcsPlanFormModel.formField;

    const { titles, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { revieweeDetails } = useAppSelector(state => state.pcsPdp);

    useReferenceData();

    if (staticDataStateStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
            <Typography variant='h4'>Practitioner Details (Requestor/PCS user)</Typography>
        </Grid>

        <Grid item xs={12}></Grid>

        <Grid item xs={12}>
            <AppLabelField value={titles?.find(x => x.id === revieweeDetails?.titleId)?.value} label={Title.label} />
        </Grid>

        <Grid item xs={12}>
            <AppLabelField value={revieweeDetails?.firstnames + ' ' +
                revieweeDetails?.surname} label={Name.label} />
        </Grid>

        <Grid item xs={12}>
            <AppLabelField value={revieweeDetails?.imcNumber} label={IMCNumber.label} />
        </Grid>

        <Grid item xs={12}></Grid>
    </>
}
import {
    Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead,
    TableRow, Typography
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { Link } from "react-router-dom";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import { AppPathEnum } from "../../../app/enums/appPathEnum";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { useEffect, useState } from "react";
import { PcsEnrolmentYear } from "../../pcsPlan/models/pcsEnrolmentYear";
import { PdpStatusEnum } from "../../pcsPlan/models/pdpStatusEnum";
import { getRevieweeRequestsAsync } from "../../../app/slices/pcsPdpSlice";
import { formatDateWithMonthName } from "../../../app/utils/util";
import { PersonalDetailsDto } from "../../../app/models/person/personalDetailsDto";
import { getPersonalDetailsAsync } from "../../../app/slices/personSlice";

export default function PdpReviewerForm() {
    const { pcsEnrolmentYears, getRevieweeRequestsStatus } = useAppSelector(state => state.pcsPdp);
    const { pcsPdpStatuses, titles, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { personalDetails, personalDetailsStatus } = useAppSelector(state => state.personalDetail);

    const [pcsEnrolmentYearsFiltered, setPcsEnrolmentYearsFiltered] = useState<PcsEnrolmentYear[] | undefined>();
    const [personInfoList, setPersonInfoList] = useState<PersonalDetailsDto[]>([]);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (pcsEnrolmentYears && pcsEnrolmentYears?.length > 0 && pcsPdpStatuses && pcsPdpStatuses.length > 0 &&
            personInfoList && personInfoList.length > 0 && titles && titles.length > 0) {
            const pcsPdpFilteredStatuses = pcsPdpStatuses?.filter(y => y.code ===
                PdpStatusEnum.ReviewRequestPending || y.code === PdpStatusEnum.UnderReview) ?? [];
            const pcsFilteredEnrolmentYears = pcsEnrolmentYears?.slice()?.filter(x => pcsPdpFilteredStatuses
                ?.find(y => y.id === x.pcsPdpReviewStatusId)).sort((a, b) => b.year - a.year);

            let pcsEnrolmentYearList: PcsEnrolmentYear[] = [];

            pcsFilteredEnrolmentYears.map((item) => {
                const personalInfo = personInfoList.find(x => x.id === item.personId);
                const title = (personalInfo?.titleId !== undefined && personalInfo?.titleId !== null)
                    ? titles?.find(x => x.id === personalInfo?.titleId)?.value : '';
                pcsEnrolmentYearList.push({ ...item, pdpReviewerName: title + ' ' + personalInfo?.fullName });
            });
            setPcsEnrolmentYearsFiltered(pcsEnrolmentYearList);
        }
    }, [pcsEnrolmentYears, pcsPdpStatuses, personInfoList, titles]);

    useEffect(() => {
        if (personalDetails !== null) {
            setPersonInfoList([...personInfoList, personalDetails]);
        }
    }, [personalDetails]);

    useEffect(() => {
        dispatch(getRevieweeRequestsAsync()).then((response) => {
            if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                const pcsEnrolmentYears = response.payload as PcsEnrolmentYear[];
                const uniqueEnrolmentYears = pcsEnrolmentYears.map(item => item.personId)
                    .filter(x => x !== null).filter((value, index, self) => self.indexOf(value) === index);

                uniqueEnrolmentYears.map(async (item) => {
                    if (item !== null) {
                        await dispatch(getPersonalDetailsAsync(item));
                    }
                });
            }
        })
    }, [dispatch]);

    useReferenceData();

    const getStatusName = (pdpStatudId: string) => {
        return pcsPdpStatuses?.find(x => x.id === pdpStatudId)?.value;
    }

    if (staticDataStateStatus === ApiRequestStatus.Pending ||
        getRevieweeRequestsStatus === ApiRequestStatus.Pending ||
        personalDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Grid container rowSpacing={2}>

            <Grid item xs={12}></Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>PCS Professional Development Plans</Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <Typography variant='subtitle1'>
                    Please click on view to access the PDP review requests for the relevant PCS practitioner.
                </Typography>
            </Grid>

            <Grid item xs={12}></Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Date of request</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pcsEnrolmentYearsFiltered?.map((item: PcsEnrolmentYear, index: number) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">
                                        {item.datePdpRequestSent !== null &&
                                            formatDateWithMonthName(new Date(item.datePdpRequestSent))}
                                    </TableCell>
                                    <TableCell align="left">{item.pdpReviewerName}</TableCell>
                                    <TableCell align="left">{item.pcsPdpReviewStatusId !== null && getStatusName(item.pcsPdpReviewStatusId)}</TableCell>
                                    <TableCell align="left">
                                        <Button component={Link} to={`${AppPathEnum.PCS_PLAN_REVIEWER_DETAILS}/${item.enrolmentYearId}${window.location.search}`} size="small">View</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {(!pcsEnrolmentYearsFiltered || pcsEnrolmentYearsFiltered?.length === 0) &&
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        No records found.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    </Paper>
}
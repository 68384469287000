import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import { GetSummaryColorCode } from "../../../../app/utils/util";
import AppInformationText from "../../../../app/components/AppInformationText";
import { SummaryDto } from "../../../../app/models/summary/summaryDto";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { useEffect, useState } from "react";
import cpdCategoriesFormModel from "../../../pcsCpd/common/cpdCategoriesFormModel";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { getCreditSummaryAsync } from "../../../../app/slices/pcsSlice";

const useStyles = makeStyles(() => ({
    root: {
        "& .MuiPaper-root": {
            width: "unset",
            marginLeft: "0"
        }
    },
    creditSummaryCell: {
        textAlign: 'center',
        paddingLeft: '1%',
        paddingRight: '1%'
    }
}));

export function CreditSummary() {
    const {
        formField: {
            AccreditedCeActivity,
            ProfessionalDevelopmentPlan,
            PracticeReview,
            WorkbasedLearning,
            OnTarget
        }
    } = cpdCategoriesFormModel;

    const classes = useStyles();
    const { currentYear, summaryData, getCreditSummaryStatus } = useAppSelector(state => state.pcs);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (summaryData === null && getCreditSummaryStatus === ApiRequestStatus.Idle) {
            dispatch(getCreditSummaryAsync());
        }
    }, [summaryData, getCreditSummaryStatus, dispatch]);

    const [creditSummarydata, setCreditSummaryData] = useState<SummaryDto[] | null>(null);
    const [isYearPartialyCompleted, setIsYearPartialyCompleted] = useState<boolean>(false);

    useEffect(() => {
        if (summaryData && currentYear !== null) {
            summaryData.map((item: SummaryDto[]) => {
                if (item[0].text.substring(0, 4) === (currentYear - 1).toString()) {
                    setCreditSummaryData(item);
                }

                let isYearPartialyComplete = false;
                if (item[2].text.toLowerCase() === "true") {
                    if (isYearPartialyComplete === false) {
                        setIsYearPartialyCompleted(true);
                    }
                }
            });
        }
    }, [summaryData, currentYear]);

    const creditSummaryHelpText = () => {
        return <>
            Green - Credits on Target<br></br>
            Yellow - Credits Approaching Target<br></br>
            Red - Credits not on target<br></br>
        </>
    }

    if (getCreditSummaryStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <div className={classes.root}>
        <Box sx={{ minWidth: "100%" }}>
            <Grid container rowSpacing={4} sx={{ paddingTop: "5%" }}>

                <Grid item xs={6} md={2} sm={3}>
                    <Typography variant='h5' sx={{ fontWeight: '550' }}>Credit summary</Typography>
                </Grid>

                <Grid item xs={1} md={1} sm={1} sx={{ marginTop: '-7px' }}>
                    <AppInformationText information_text={creditSummaryHelpText()} placement={"top"} />
                </Grid>

                <Grid item xs={5} md={9} sm={8}></Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "90%", width: "98%" }}>
            <Grid container rowSpacing={4}>

                <Grid item xs={12}></Grid>

                <Grid item xs={1} sx={{ textAlign: 'start', wordBreak: 'break-all' }}>
                    <b>PCS Year</b>
                </Grid>

                <Grid item xs={2} className={classes.creditSummaryCell}>
                    <b>Engaged in  Practice of Medicine</b>
                </Grid>

                <Grid item xs={1.5} className={classes.creditSummaryCell}>
                    <b>{AccreditedCeActivity.label}</b>
                    <br />
                    <AppInformationText information_text={AccreditedCeActivity.informationText} placement="bottom" />
                </Grid>

                <Grid item xs={1.5} className={classes.creditSummaryCell}>
                    <b>{ProfessionalDevelopmentPlan.label}</b>
                    <br />
                    <AppInformationText information_text={ProfessionalDevelopmentPlan.informationText} placement="bottom" />
                </Grid>

                <Grid item xs={1.5} className={classes.creditSummaryCell}>
                    <b>{PracticeReview.label}</b>
                    <br />
                    <AppInformationText information_text={PracticeReview.informationText} placement="bottom" />
                </Grid>

                <Grid item xs={1.5} className={classes.creditSummaryCell}>
                    <b>{WorkbasedLearning.label}</b>
                    <br />
                    <AppInformationText information_text={WorkbasedLearning.informationText} placement="bottom" />
                </Grid>

                <Grid item xs={1.5} className={classes.creditSummaryCell}>
                    <b>{OnTarget.label}</b>
                    <br />
                    <AppInformationText information_text={OnTarget.informationText} placement="bottom" />
                </Grid>

                <Grid item xs={1.5} className={classes.creditSummaryCell}>
                    <b>Verification Status</b>
                </Grid>
            </Grid>

            <Grid container sx={{ textAlign: 'center' }}>
                {creditSummarydata !== null && creditSummarydata.length > 0 && <>
                    <Grid item xs={1} sx={{ paddingTop: 2, textAlign: 'start' }}>
                        {creditSummarydata && creditSummarydata[0].text}
                        {creditSummarydata && creditSummarydata[2].text.toLowerCase() === "true" ? "*" : ""}
                    </Grid>

                    <Grid item xs={2} sx={{ padding: 2 }}>
                        {creditSummarydata && creditSummarydata[1].text}
                    </Grid>

                    <Grid item xs={1.5} sx={{ padding: 1 }}>
                        <Grid sx={{
                            backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[3].status.toUpperCase()),
                            textAlign: 'center',
                            padding: 1
                        }}>
                            <b>{creditSummarydata && creditSummarydata[3].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[3].text.split('(')[1]}
                        </Grid>
                    </Grid>

                    <Grid item xs={1.5} sx={{ padding: 1 }}>
                        <Grid sx={{
                            backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[4].status?.toUpperCase()),
                            textAlign: 'center',
                            padding: 1
                        }}>
                            <b>{creditSummarydata && creditSummarydata[4].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[4].text.split('(')[1]}
                        </Grid>
                    </Grid>

                    <Grid item xs={1.5} sx={{ padding: 1 }}>
                        <Grid sx={{
                            backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[5].status?.toUpperCase()),
                            textAlign: 'center',
                            padding: 1
                        }}>
                            <b>{creditSummarydata && creditSummarydata[5].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[5].text.split('(')[1]}
                        </Grid>
                    </Grid>

                    <Grid item xs={1.5} sx={{ padding: 1 }}>
                        <Grid sx={{
                            backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[6].status?.toUpperCase()),
                            textAlign: 'center',
                            padding: 1
                        }}>
                            <b>{creditSummarydata && creditSummarydata[6].text.split('(')[0]}</b>({creditSummarydata && creditSummarydata[6].text.split('(')[1]}
                        </Grid>
                    </Grid>

                    <Grid item xs={1.5} sx={{ padding: 1 }}>
                        <Grid sx={{
                            backgroundColor: creditSummarydata && GetSummaryColorCode(creditSummarydata[7].status?.toUpperCase()),
                            textAlign: 'center',
                            padding: 1
                        }}>
                            <b>{creditSummarydata && creditSummarydata[7].text}</b>
                        </Grid>
                    </Grid>

                    <Grid item xs={1.5} sx={{
                        paddingTop: 2
                    }}>
                        {creditSummarydata && creditSummarydata[8].text}
                    </Grid>
                </>}

                {(creditSummarydata === null || creditSummarydata?.length === 0) &&
                    <Grid item xs={12} sx={{ textAlign: 'center', padding: 5 }}>No records found.</Grid>
                }

                {isYearPartialyCompleted === true &&
                    <Grid item xs={12} sx={{ paddingTop: 2, textAlign: 'start' }}>
                        *Partial Year Completed.
                    </Grid>
                }

                <Grid item xs={12} sx={{ paddingTop: 2, textAlign: 'start' }}>
                    Note: CPD Category requirements are displayed in brackets.
                </Grid>

            </Grid>
        </Box>
    </div>
}
import { useMsal } from "@azure/msal-react";
import { Box, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { IdTokenClaims } from "../../../app/models/account/idTokenClaims";
import registrationFormModel from "../../../app/models/pcsEnrolment/registrationFormModel";
import { useAppSelector } from "../../../app/store/configureStore";
import { formatDate, pcsFilter } from "../../../app/utils/util";

export function WorkDetails() {
  const {
    workDetailsFormField: {
      StartDate,
      PostLevel,
      Country,
      NameOfClinicalSupervisor,
      NameOfEmployer,
      OtherNameOfEmployer,
      JobTitle
    }
  } = registrationFormModel;

  const methods = useFormContext();
  const { reset } = methods;

  const { instance } = useMsal();
  const userRoles = (instance.getActiveAccount()?.idTokenClaims as unknown as IdTokenClaims).extension_portalcrm_userType.split(',');

  const { countries, workPostLevels, hospitals } = useAppSelector(state => state.ReferenceData);
  const { revieweeDetails } = useAppSelector(state => state.pcsPdp);

  useEffect(() => {
    reset({
      CurrentUserRoles: userRoles
    });
  }, []);

  return <Box sx={{
    width: '96%', backgroundColor: '#e7e7e7', padding: '25px',
    paddingRight: '0px', marginTop: '10px', marginBottom: '2%'
  }}>
    <Grid container rowSpacing={4}>

      <Grid item xs={12}>
        <Typography variant='h5' sx={{ fontWeight: '550' }}>Practitioner's current employment details</Typography>
      </Grid>

      <Grid item xs={4}>
        <b>{StartDate.label.replace("*", "")}</b>
      </Grid>

      <Grid item xs={0.5}></Grid>
      
      <Grid item xs={4}>
        {revieweeDetails?.workPost?.startDate && revieweeDetails?.workPost?.startDate !== null &&
          formatDate(new Date(revieweeDetails?.workPost?.startDate))}
      </Grid>

      <Grid item xs={3.5}></Grid>

      {userRoles && pcsFilter(userRoles) && <>
        <Grid item xs={4}>
          <b>{PostLevel.label.replace("*", "")}</b>
        </Grid>

        <Grid item xs={0.5}></Grid>

        <Grid item xs={4}>
          {workPostLevels?.find(x => x.id === revieweeDetails?.workPost?.workPostLevelId)?.value}
        </Grid>

        <Grid item xs={3.5}></Grid>
      </>}

      <Grid item xs={4}>
        <b>{JobTitle.label.replace("*", "")}</b>
      </Grid>

      <Grid item xs={0.5}></Grid>

      <Grid item xs={4}>
        {revieweeDetails?.workPost?.jobTitle}
      </Grid>

      <Grid item xs={3.5}></Grid>

      <Grid item xs={4}>
        <b>{Country.label.replace("*", "")}</b>
      </Grid>

      <Grid item xs={0.5}></Grid>

      <Grid item xs={4}>
        {countries && countries?.find(x => x.id === revieweeDetails?.workPost?.countryId)?.value}
      </Grid>

      <Grid item xs={3.5}></Grid>

      {userRoles && pcsFilter(userRoles) &&
        <>
          <Grid item xs={4}>
            <b>{NameOfClinicalSupervisor.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {revieweeDetails?.workPost?.clinicalSupervisor}
          </Grid>

          <Grid item xs={3.5}></Grid>
        </>
      }

      {revieweeDetails?.workPost?.employerId !== undefined && revieweeDetails?.workPost?.employerId !== null &&
        revieweeDetails?.workPost?.employerId !== "" && <>
          <Grid item xs={4}>
            <b>{NameOfEmployer.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {hospitals?.find(x => x.id === revieweeDetails?.workPost?.employerId)?.value}
          </Grid>

          <Grid item xs={3.5}></Grid>
        </>}

      {revieweeDetails?.workPost?.employerOther !== undefined && revieweeDetails?.workPost?.employerOther !== null &&
        revieweeDetails?.workPost?.employerOther !== "" && <>
          <Grid item xs={4}>
            <b>{OtherNameOfEmployer.label.replace("*", "")}</b>
          </Grid>

          <Grid item xs={0.5}></Grid>

          <Grid item xs={4}>
            {revieweeDetails?.workPost?.employerOther}
          </Grid>

          <Grid item xs={3.5}></Grid>
        </>}
    </Grid>
  </Box>
}

import { Box, Grid, Typography, Button, Paper } from "@mui/material";
import { useEffect, useState } from "react";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { deleteDirectDebitMandateByIdAsync, getDirectDebitMandateByIdAsync } from "../../../app/slices/directDebitMandateSlice";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { useHistory, useParams } from "react-router-dom";
import { AppPathEnum } from "../../../app/enums/appPathEnum";
import { ProductEnum } from "../../../app/enums/productEnum";
import { getCurrencyIconByCode } from "../../../app/utils/util";
import { toast } from "react-toastify";
import UnsavedChangesDialog from "../../../app/components/UnsavedChangesDialog";
import { PaymentFrequencyEnum } from "../../../app/enums/paymentFrequencyEnum";
import { useReferenceData } from "../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import DirectDebitFormModel from "../../../app/models/directDebit/directDebitFormModel";

const ManageDirectDebit = () => {
    const {
        formField: {
            UniqueMandateReference,
            Product,
            Amount,
            Frequency,
            BIC,
            IBAN,
            Creditor
        }
    } = DirectDebitFormModel;

    const history = useHistory();
    const { directDebitId } = useParams<{ directDebitId: string }>();

    const [pageLoading, setPageLoading] = useState(false);
    const [cancelDirectDebitMandateConfirmation, setCancelDirectDebitMandateConfirmation] = useState(false);

    const dispatch = useAppDispatch();

    const { directDebitMandate, directDebitMandateByIdStatus } = useAppSelector(state => state.directDebit);
    const { directDebitCreditors, products, subscriptionIntervalUnits, currencies } = useAppSelector(state => state.ReferenceData);

    const { staticDataStateStatus } = useReferenceData();

    useEffect(() => {
        dispatch(getDirectDebitMandateByIdAsync(directDebitId));
    }, [dispatch, directDebitId]);

    const backToMyDirectDebitMandates = () => {
        const queryParams = new URLSearchParams(window.location.search);
        const initialStep = queryParams.get("initialStep");
        if (!initialStep || initialStep === null) {
            history.push(`${AppPathEnum.MY_RECEIPTS}/${window.location.search}&initialStep=2`);
        }
        else {
            history.push(`${AppPathEnum.MY_RECEIPTS}/${window.location.search}`);
        }
    }

    const cancelDirectDebitMandate = () => {
        setPageLoading(true);
        if (directDebitMandate?.id) {
            dispatch(deleteDirectDebitMandateByIdAsync(directDebitMandate?.id)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    toast.success("Direct debit mandate cancelled successfully.");
                    backToMyDirectDebitMandates();
                    setPageLoading(false);
                }
                else {
                    setPageLoading(false);
                }
            });
        }
    }

    if (pageLoading === true || staticDataStateStatus === ApiRequestStatus.Pending
        || directDebitMandateByIdStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return (
        <>
            <Box sx={{ width: '100%', paddingRight: '2%' }} component={Paper}>

                <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                    <Grid item xs={12}>
                        <Typography variant='h4'>Manage Direct Debit</Typography>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <Typography variant='body1' sx={{ color: 'primary.main' }}>
                            Please note BICs and IBANs cannot be edited, please cancel and see email instructions on how to create a new direct debit mandate.
                        </Typography>
                    </Grid>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{UniqueMandateReference.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {directDebitMandate?.reference}
                    </Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{Product.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {products?.find(x => x.id === directDebitMandate?.basketProducts[0].productId)?.name}
                    </Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{Amount.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {products?.find(x => x.id === directDebitMandate?.basketProducts[0].productId)?.code ===
                            ProductEnum.FamAnnualMembership ? 'Membership Rate' :
                            `${getCurrencyIconByCode(currencies?.find(x => x.id ===
                                directDebitMandate?.basketProducts[0].currencyId)?.code)}
                         ${directDebitMandate?.basketProducts[0].cost}`}
                    </Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{Frequency.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {directDebitMandate?.intervalCount === -1 && "-"}
                        {directDebitMandate?.intervalCount !== -1 && ((directDebitMandate?.intervalCount !== null && directDebitMandate?.intervalCount === 3) ?
                            PaymentFrequencyEnum.Quarterly : products?.find(x => x.id === directDebitMandate?.basketProducts[0].productId)?.code ===
                                ProductEnum.FamAnnualMembership ? PaymentFrequencyEnum.Annual : `${subscriptionIntervalUnits?.find(x => x.id ===
                                    directDebitMandate?.intervalUnitId)?.code.toUpperCase() === PaymentFrequencyEnum.Year.toUpperCase() ?
                                    PaymentFrequencyEnum.Annual : PaymentFrequencyEnum.Monthly}`)}
                    </Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{BIC.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {directDebitMandate?.bic}
                    </Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{IBAN.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {directDebitMandate?.iban}
                    </Grid>

                    <Grid item xs={12} sm={4} className="wordWrap">
                        <b>{Creditor.label}</b>
                    </Grid>

                    <Grid item xs={12} sm={8} className="wordWrap">
                        {directDebitCreditors?.find(x => x.id === directDebitMandate?.directDebitCreditorId)?.value}
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} className="wordWrap">
                        <Button variant="outlined" color="primary" onClick={backToMyDirectDebitMandates}>
                            Back to My Direct Debits
                        </Button>
                    </Grid>

                    <Grid item xs={0} sm={0.5} className="wordWrap"></Grid>

                    <Grid item xs={12} sm={5.5} className="wordWrap">
                        <Button variant="contained" color="primary" onClick={() => setCancelDirectDebitMandateConfirmation(true)}
                            disabled={!directDebitMandate?.canCancel}>
                            Cancel Direct Debit
                        </Button>
                    </Grid>

                    {!directDebitMandate?.canCancel && <Grid item xs={12} sx={{ color: 'primary.main' }}>
                        Please note: Direct Debit Mandates cannot be cancelled until the first scheduled payment has been collected.
                    </Grid>}

                    <Grid item xs={12}></Grid>

                </Grid>
            </Box>

            {
                cancelDirectDebitMandateConfirmation === true &&
                <UnsavedChangesDialog showDialog={true}
                    title={'Are you sure you want to delete direct debit mandate?'}
                    subTitle={`You will loose the changes on clicking "YES".`}
                    yesButtonClick={() => {
                        cancelDirectDebitMandate();
                        setCancelDirectDebitMandateConfirmation(false);
                    }}
                    noButtonClick={() => setCancelDirectDebitMandateConfirmation(false)}
                    showYesNoButtons={true} />
            }
        </>
    );
};

export default ManageDirectDebit;
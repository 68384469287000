import { Box, Button, Grid, Step, StepLabel, Stepper } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PersonalDetails from './PersonalDetails';
import { registrationValidationSchema } from '../../app/models/pcsEnrolment/registrationValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useAppDispatch, useAppSelector } from '../../app/store/configureStore';
import {
  API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED, MESSAGE_SAVE_AND_CONTINUE,
  MESSAGE_NEXT, MESSAGE_BACK_TO_PREVIOUS_STEP, MESSAGE_PROCESSING_DETAILS
} from '../../app/utils/constant';
import { getPersonalDetailsAsync, updatePersonalDetailsStateAsync } from '../../app/slices/personSlice';
import { PersonalDetailsDto } from '../../app/models/person/personalDetailsDto';
import { ApiRequestStatus } from '../../app/enums/apiRequestStatus';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { toast } from 'react-toastify';
import { useMsal } from '@azure/msal-react';
import { UserClaims } from '../../app/enums/userClaims';
import EducationalDetails from './EducationalDetails';
import RegistrationDetails from './RegistrationDetails';
import Header from './Header';
import { useReferenceData } from '../../app/customHooks/useReferenceData';
import { useGetPersonalDetails } from '../../app/customHooks/useGetPersonalDetails';
import { useGetRegistrationDetails } from '../../app/customHooks/useGetRegistrationDetails';
import { PcsEnrolmentStepEnum } from '../../app/models/pcsEnrolment/pcsEnrolmentStepEnum';
import { WorkPostDto } from '../../app/models/workpost/workPostDto';
import { getWorkpostListAsync, saveWorkpostAsync } from '../../app/slices/workpostSlice';
import { getRegistrationDetailsAsync, saveRegistrationDetailsAsync, updateEducationAsync } from '../../app/slices/pcsSlice';
import { UpdateEducationCommand } from '../../app/models/person/updateEducationCommand';
import { PCSDetailsDto } from '../../app/models/pcsEnrolment/pcsDetailsDto';
import WorkDetails from './workDetails/WorkDetails';
import Payment from './Payment';
import { SpaName } from '../../app/enums/spaName';
import { useCanEnrol } from '../../app/customHooks/useCanEnrol';
import Unauthorized from '../../app/errors/Unauthorized';
import { PCS_ALREADY_ENROLED } from '../membershipRenewal/common/messages';

interface Prop {
  reenrol: boolean;
}

export default function Enrolment({ reenrol }: Prop) {
  const queryParams = new URLSearchParams(window.location.search);
  const expectedStep = queryParams.get("expectedStep");

  const steps = [PcsEnrolmentStepEnum.RegistrationDetails, PcsEnrolmentStepEnum.PersonalDetails,
  PcsEnrolmentStepEnum.EducationalDetails, PcsEnrolmentStepEnum.WorkDetails, PcsEnrolmentStepEnum.Payment];

  function getStepContent(step: number) {
    switch (step) {
      case 0:
        return <RegistrationDetails reenrol={reenrol} />
      case 1:
        return <PersonalDetails reenrol={reenrol} />
      case 2:
        return <EducationalDetails reenrol={reenrol} />
      case 3:
        return <WorkDetails nextStepClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)} handleSave={handleNext} spaName={SpaName.PCS_ENROLMENT} />
      case 4:
        return <Payment reenrol={reenrol} />
      default:
        throw new Error('Unknown step');
    }
  }

  const [activeStep, setActiveStep] = useState((expectedStep === undefined || expectedStep === null) ? 0 : parseInt(expectedStep));
  const currentValidationSchema = registrationValidationSchema[activeStep];
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(currentValidationSchema)
  });
  const { trigger, formState: { isDirty } } = methods;
  const form = methods.watch();

  const { accounts } = useMsal();

  useEffect(() => {
    if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
      setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
      setPcsFeesDue(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PCSFEESDUE] as boolean);
    }
  }, [accounts]);

  const [personId, setPersonId] = useState('');
  const [pcsFeesDue, setPcsFeesDue] = useState<boolean | undefined>();

  const dispatch = useAppDispatch();

  const { staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
  const { registrationDetails, canEnrol, registerStateStatus, saveRegistrationDetailsStatus,
    updateEducationalDetailsStatus, canEnrolStatus } = useAppSelector(state => state.pcs);
  const { personalDetails, personalDetailsStatus, updatePersonalDetailsStatus } = useAppSelector(state => state.personalDetail);
  const { workpostStatus } = useAppSelector(state => state.workpost);

  useReferenceData();
  useGetRegistrationDetails();
  useGetPersonalDetails();
  useCanEnrol(reenrol);

  const handleNext = async () => {
    const isStepValid = await trigger();

    if (isStepValid) {
      if (isDirty === true) {
        switch (activeStep) {
          case 0:
            let registrationDetails = MapToRegistrationDetailsDto();
            dispatch(saveRegistrationDetailsAsync(registrationDetails)).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                dispatch(getRegistrationDetailsAsync()).then((registrationDetailsResponse) => {
                  if (registrationDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                  }
                });
              }
            });
            break;
          case 1:
            let personalDetailsDto = MapToPersonalDetailsDto();
            dispatch(updatePersonalDetailsStateAsync(personalDetailsDto)).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                dispatch(getPersonalDetailsAsync(personId)).then((personalDetailsResponse) => {
                  if (personalDetailsResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                  }
                });
              }
            });
            break;
          case 2:
            dispatch(updateEducationAsync(MapToUpdateEducationCommand())).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                dispatch(getRegistrationDetailsAsync()).then((response) => {
                  if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    setActiveStep(prevActiveStep => prevActiveStep + 1);
                  }
                });
              }
            });
            break;
          case 3:
            let workpostDto = MapToWorkDetailsDto();
            dispatch(saveWorkpostAsync(workpostDto)).then((response: any) => {
              if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                toast.success(MESSAGE_DETAILS_SAVED);
                setActiveStep(prevActiveStep => prevActiveStep + 1);
                dispatch(getWorkpostListAsync());
              }
            });
            break;
          default:
            return "not a valid step";
        }
      }
      else if (isDirty === false) {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
      }
    }
  };

  async function _handleBack() {
    setActiveStep(activeStep - 1);
  }

  function MapToRegistrationDetailsDto() {
    const data: PCSDetailsDto = {
      imcNumber: reenrol === false ? form.IMCNumber : registrationDetails?.imcNumber,
      registrationTypeId: form.RegistrationType,
      schemeId: form.Scheme,
      specialtyId: form.Specialty,
      engagedInPracticeOfMedicine: form.EngagedInPracticeOfMedicine === '1',
      subSpeciality: form.SubSpecialty,
      declaration: form.UserConfirmation.length > 0
    }
    return data;
  }

  function MapToPersonalDetailsDto() {
    const data: PersonalDetailsDto = {
      id: personalDetails?.id,
      titleId: form.Title,
      genderId: form.Gender,
      nationalityId: form.Nationality,
      firstName: personalDetails?.firstName,
      lastName: personalDetails?.lastName,
      preferredEmail: personalDetails?.preferredEmail,
      dateOfBirth: form.DOB,
      mobile: form.MobileNumber
    }
    return data;
  }

  function MapToUpdateEducationCommand() {
    let institution = "";
    if (form.OtherPrimaryMedicalDegreeCollege === undefined ||
      form.OtherPrimaryMedicalDegreeCollege === "") {
      institution = form.PrimaryMedicalDegreeCollege;
    }
    else if (form.OtherPrimaryMedicalDegreeCollege !== "") {
      institution = "";
    }

    const data: UpdateEducationCommand = {
      qualificationCountryId: form.QualificationCountry,
      qualificationInstitutionId: institution,
      qualificationInstitutionOther: form.OtherPrimaryMedicalDegreeCollege,
      qualificationYear: form.QualificationYear
    }
    return data;
  }

  function MapToWorkDetailsDto() {
    const data: WorkPostDto = {
      id: null,
      startDate: form.StartDate,
      workPostLevelId: form.PostLevel,
      countryId: form.Country,
      employerId: form.NameOfEmployer,
      employerOther: form.NameOfEmployer === '' ? form.OtherNameOfEmployer : '',
      clinicalSupervisor: form.NameOfClinicalSupervisor,
      jobTitle: form.JobTitle
    }
    return data;
  }

  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const isMobile = width <= 768;

  if (staticDataStateStatus === ApiRequestStatus.Pending || registerStateStatus === ApiRequestStatus.Pending
    || personalDetailsStatus === ApiRequestStatus.Pending || saveRegistrationDetailsStatus === ApiRequestStatus.Pending
    || updatePersonalDetailsStatus === ApiRequestStatus.Pending || updateEducationalDetailsStatus === ApiRequestStatus.Pending
    || workpostStatus === ApiRequestStatus.Pending || canEnrolStatus === ApiRequestStatus.Pending) {
    return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
  }

  if (reenrol === true && pcsFeesDue === false) {
    return <Unauthorized message={PCS_ALREADY_ENROLED} />
  }

  if (canEnrol === false) {
    return <Unauthorized message={PCS_ALREADY_ENROLED} />
  }

  return (
    <FormProvider {...methods}>
      <Header reenrol={reenrol} />

      <br></br>

      <Stepper activeStep={activeStep} orientation={isMobile === true ? "vertical" : "horizontal"}>
        {steps.map((label) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <br></br>

      {activeStep !== 0 && (
        <Button variant="contained" onClick={_handleBack}>
          {MESSAGE_BACK_TO_PREVIOUS_STEP}
        </Button>
      )}

      {activeStep < steps.length &&
        <>
          {getStepContent(activeStep)}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
              <Grid item xs={12}></Grid>
              <Grid item xs={3}></Grid>
              <Grid item xs={8} sm={8} sx={{ display: 'flex', justifyContent: 'end' }}>
                {activeStep <= steps.length - 3 && <Button variant="contained" color="primary" onClick={handleNext}>
                  {isDirty === true ? MESSAGE_SAVE_AND_CONTINUE : MESSAGE_NEXT}
                </Button>}
              </Grid>
              <Grid item xs={1}></Grid>
            </Grid>
          </Box>
        </>}
    </FormProvider >
  );
};

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import agent from "../api/agent";
import { ApiRequestStatus } from "../enums/apiRequestStatus";
import { PlannedActivity } from "../../features/pcsPlan/models/plannedActivity";
import { ReviewerDetails } from "../../features/pcsPlan/models/reviewerDetails";
import { ReviewerDetailsDto } from "../../features/pcsPlan/models/reviewerDetailsDto";
import { DomainSummaryDto } from "../../features/pcsPlan/models/domainSummaryDto";
import { ConfirmReviewerDetailsParameters } from "../../features/pcsPlan/models/confirmReviewerDetailsParameters";
import { RevieweeCommentDto } from "../../features/pcsPlan/models/revieweeCommentDto";
import { PcsEnrolmentYear } from "../../features/pcsPlan/models/pcsEnrolmentYear";
import { ReviewPcsPdpCommandDto } from "../../features/pcsPlan/models/reviewPcsPdpCommandDto";
import { RevieweeDetailsDto } from "../../features/pcsPlan/models/revieweeDetailsDto";

interface PlanState {
    pcsEnrolmentYears: PcsEnrolmentYear[] | null;
    isPlannedActivityUpdated: boolean | null;
    reviewerDetails: ReviewerDetailsDto | null;
    revieweeDetails: RevieweeDetailsDto | null;
    activitiesAcrossDomains: DomainSummaryDto | null;
    planStateStatus: ApiRequestStatus;
    confirmReviewerDetailsStatus: ApiRequestStatus;
    submitReviewerDetailsStatus: ApiRequestStatus;
    searchReviewerByImcNumberStatus: ApiRequestStatus;
    searchReviewerByPersonIdStatus: ApiRequestStatus;
    updatePlannedActivityStatus: ApiRequestStatus;
    getActivitiesAcrossDomainsStatus: ApiRequestStatus;
    cancelReviewRequestStatus: ApiRequestStatus;
    saveRevieweeCommentsStatus: ApiRequestStatus;
    getRevieweeRequestsStatus: ApiRequestStatus;
    submitReviewStatus: ApiRequestStatus;
    declineReviewStatus: ApiRequestStatus;
    getRevieweeDetailsStatus: ApiRequestStatus;
}

const initialState: PlanState = {
    pcsEnrolmentYears: null,
    isPlannedActivityUpdated: null,
    reviewerDetails: null,
    revieweeDetails: null,
    activitiesAcrossDomains: null,
    planStateStatus: ApiRequestStatus.Idle,
    confirmReviewerDetailsStatus: ApiRequestStatus.Idle,
    submitReviewerDetailsStatus: ApiRequestStatus.Idle,
    searchReviewerByImcNumberStatus: ApiRequestStatus.Idle,
    searchReviewerByPersonIdStatus: ApiRequestStatus.Idle,
    updatePlannedActivityStatus: ApiRequestStatus.Idle,
    getActivitiesAcrossDomainsStatus: ApiRequestStatus.Idle,
    cancelReviewRequestStatus: ApiRequestStatus.Idle,
    saveRevieweeCommentsStatus: ApiRequestStatus.Idle,
    getRevieweeRequestsStatus: ApiRequestStatus.Idle,
    submitReviewStatus: ApiRequestStatus.Idle,
    declineReviewStatus: ApiRequestStatus.Idle,
    getRevieweeDetailsStatus: ApiRequestStatus.Idle
}

export const getRevieweeRequestsAsync = createAsyncThunk<PcsEnrolmentYear[]>(
    'pcsPdp/getRevieweeRequestsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PcsPdp.getRevieweeRequestsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getActivitiesAcrossDomainsAsync = createAsyncThunk<DomainSummaryDto>(
    'pcsPdp/getActivitiesAcrossDomainsAsync',
    async (_, thunkAPI) => {
        try {
            return agent.PcsPdp.getActivitiesAcrossDomainsAsync();
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchReviewerByImcNumberAsync = createAsyncThunk<ReviewerDetailsDto, string>(
    'pcsPdp/searchReviewerByImcNumberAsync',
    async (imcNumber, thunkAPI) => {
        try {
            return agent.PcsPdp.searchReviewerByImcNumberAsync(imcNumber);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const searchReviewerByPersonIdAsync = createAsyncThunk<ReviewerDetailsDto, string>(
    'pcsPdp/searchReviewerByPersonIdAsync',
    async (personId, thunkAPI) => {
        try {
            return agent.PcsPdp.searchReviewerByPersonIdAsync(personId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const updatePlannedActivityAsync = createAsyncThunk<boolean, PlannedActivity>(
    'pcsPdp/updatePlannedActivityAsync',
    async (plannedActivity, thunkAPI) => {
        try {
            return agent.PcsPdp.updatePlannedActivityAsync(plannedActivity);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const confirmReviewerDetailsAsync = createAsyncThunk<boolean, ConfirmReviewerDetailsParameters>(
    'pcsPdp/confirmReviewerDetailsAsync',
    async (confirmReviewerDetailsParameters, thunkAPI) => {
        try {
            return agent.PcsPdp.confirmReviewerDetailsAsync(confirmReviewerDetailsParameters);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const getRevieweeDetailsAsync = createAsyncThunk<RevieweeDetailsDto, string>(
    'pcsPdp/getRevieweeDetailsAsync',
    async (enrolmentYearId, thunkAPI) => {
        try {
            return agent.PcsPdp.getRevieweeDetailsAsync(enrolmentYearId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitReviewerDetailsAsync = createAsyncThunk<boolean, ReviewerDetails>(
    'pcsPdp/submitReviewerDetailsAsync',
    async (reviewerDetails, thunkAPI) => {
        try {
            return agent.PcsPdp.submitReviewerDetailsAsync(reviewerDetails);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const saveRevieweeCommentsAsync = createAsyncThunk<boolean, RevieweeCommentDto>(
    'pcsPdp/saveRevieweeCommentsAsync',
    async (revieweeComment, thunkAPI) => {
        try {
            return agent.PcsPdp.saveRevieweeCommentAsync(revieweeComment);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const cancelReviewRequestAsync = createAsyncThunk<boolean, string>(
    'pcsPdp/cancelReviewRequestAsync',
    async (enrolmentYearId, thunkAPI) => {
        try {
            return agent.PcsPdp.cancelReviewRequestAsync(enrolmentYearId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const submitReviewAsync = createAsyncThunk<boolean, ReviewPcsPdpCommandDto>(
    'pcsPdp/submitReviewAsync',
    async (reviewPcsPdpCommandDto, thunkAPI) => {
        try {
            return agent.PcsPdp.submitReviewAsync(reviewPcsPdpCommandDto);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const declineReviewAsync = createAsyncThunk<boolean, string>(
    'pcsPdp/declineReviewAsync',
    async (enrolmentYearId, thunkAPI) => {
        try {
            return agent.PcsPdp.declineAsync(enrolmentYearId);
        } catch (error: any) {
            return thunkAPI.rejectWithValue({ error: error.data });
        }
    }
)

export const pcsPdpSlice = createSlice({
    name: 'pcsPdpSlice',
    initialState,
    reducers: {

    },
    extraReducers: (builder => {
        builder.addCase(getRevieweeRequestsAsync.pending, (state) => {
            state.getRevieweeRequestsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getRevieweeRequestsAsync.fulfilled, (state, action) => {
            state.pcsEnrolmentYears = action.payload;
            state.getRevieweeRequestsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getRevieweeRequestsAsync.rejected, (state) => {
            state.getRevieweeRequestsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getActivitiesAcrossDomainsAsync.pending, (state) => {
            state.getActivitiesAcrossDomainsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getActivitiesAcrossDomainsAsync.fulfilled, (state, action) => {
            state.activitiesAcrossDomains = action.payload;
            state.getActivitiesAcrossDomainsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getActivitiesAcrossDomainsAsync.rejected, (state) => {
            state.getActivitiesAcrossDomainsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(updatePlannedActivityAsync.pending, (state) => {
            state.updatePlannedActivityStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(updatePlannedActivityAsync.fulfilled, (state, action) => {
            state.isPlannedActivityUpdated = action.payload;
            state.updatePlannedActivityStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(updatePlannedActivityAsync.rejected, (state) => {
            state.updatePlannedActivityStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitReviewerDetailsAsync.pending, (state) => {
            state.submitReviewerDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitReviewerDetailsAsync.fulfilled, (state) => {
            state.submitReviewerDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitReviewerDetailsAsync.rejected, (state) => {
            state.submitReviewerDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(cancelReviewRequestAsync.pending, (state) => {
            state.cancelReviewRequestStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(cancelReviewRequestAsync.fulfilled, (state) => {
            state.reviewerDetails = null;
            state.cancelReviewRequestStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(cancelReviewRequestAsync.rejected, (state) => {
            state.cancelReviewRequestStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(confirmReviewerDetailsAsync.pending, (state) => {
            state.confirmReviewerDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(confirmReviewerDetailsAsync.fulfilled, (state) => {
            state.confirmReviewerDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(confirmReviewerDetailsAsync.rejected, (state) => {
            state.confirmReviewerDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(getRevieweeDetailsAsync.pending, (state) => {
            state.getRevieweeDetailsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(getRevieweeDetailsAsync.fulfilled, (state, action) => {
            state.revieweeDetails = action.payload;
            state.getRevieweeDetailsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(getRevieweeDetailsAsync.rejected, (state) => {
            state.getRevieweeDetailsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(searchReviewerByImcNumberAsync.pending, (state) => {
            state.searchReviewerByImcNumberStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(searchReviewerByImcNumberAsync.fulfilled, (state, action) => {
            state.reviewerDetails = action.payload;
            state.searchReviewerByImcNumberStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(searchReviewerByImcNumberAsync.rejected, (state) => {
            state.searchReviewerByImcNumberStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(searchReviewerByPersonIdAsync.pending, (state) => {
            state.searchReviewerByPersonIdStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(searchReviewerByPersonIdAsync.fulfilled, (state, action) => {
            state.reviewerDetails = action.payload;
            state.searchReviewerByPersonIdStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(searchReviewerByPersonIdAsync.rejected, (state) => {
            state.searchReviewerByPersonIdStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(saveRevieweeCommentsAsync.pending, (state) => {
            state.saveRevieweeCommentsStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(saveRevieweeCommentsAsync.fulfilled, (state) => {
            state.saveRevieweeCommentsStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(saveRevieweeCommentsAsync.rejected, (state) => {
            state.saveRevieweeCommentsStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(submitReviewAsync.pending, (state) => {
            state.submitReviewStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(submitReviewAsync.fulfilled, (state) => {
            state.submitReviewStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(submitReviewAsync.rejected, (state) => {
            state.submitReviewStatus = ApiRequestStatus.Rejected;
        });

        builder.addCase(declineReviewAsync.pending, (state) => {
            state.declineReviewStatus = ApiRequestStatus.Pending;
        });
        builder.addCase(declineReviewAsync.fulfilled, (state) => {
            state.declineReviewStatus = ApiRequestStatus.Fulfilled;
        });
        builder.addCase(declineReviewAsync.rejected, (state) => {
            state.declineReviewStatus = ApiRequestStatus.Rejected;
        });
    })
})

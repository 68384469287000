import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { PcsEnrolmentYear } from "../../models/pcsEnrolmentYear";
import { Link } from "react-router-dom";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { useEffect } from "react";
import { getPcsEnrolmentYearsAsync } from "../../../../app/slices/pcsEnrolmentSlice";

export default function PcsPlanDashboard() {
    const dispatch = useAppDispatch();

    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);

    useReferenceData();

    useEffect(() => {
        dispatch(getPcsEnrolmentYearsAsync());
    }, [dispatch]);

    const getStatusName = (pdpStatudId: string) => {
        return pcsPdpStatuses?.find(x => x.id === pdpStatudId)?.value;
    }

    if (staticDataStateStatus === ApiRequestStatus.Pending ||
        enrolmentYearsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
        <Grid container rowSpacing={2}>

            <Grid item xs={12}></Grid>

            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant='h4'>PCS Professional Development Plans</Typography>
            </Grid>

            <Grid item xs={12}>
                <Typography variant='subtitle1'>
                    Please click on view to access your professional development plan for the relevant PCS Year.
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>PCS Year</TableCell>
                                <TableCell align="left">Status</TableCell>
                                <TableCell align="left"></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {enrolmentYears?.slice()?.sort((a, b) => b.year - a.year)?.map((item: PcsEnrolmentYear, index: number) => (
                                <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell align="left">{`${item.year} - ${item.year + 1}`}</TableCell>
                                    <TableCell align="left">{item.pcsPdpReviewStatusId !== null && getStatusName(item.pcsPdpReviewStatusId)}</TableCell>
                                    <TableCell align="left">
                                        <Button component={Link} to={`${AppPathEnum.PCS_PLAN_DETAILS}/${item.enrolmentYearId}${window.location.search}`} size="small">View</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {(enrolmentYears === null || enrolmentYears?.length === 0) &&
                                <TableRow>
                                    <TableCell colSpan={9} align="center">
                                        You have no active enrolments, please go to the home page to enrol.
                                    </TableCell>
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Grid>
    </Paper>
}
import { Box, Paper, Grid, Typography, Button } from "@mui/material";
import BackToRequests from "./BackToRequests";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import AppTextareaInput from "../../app/components/AppTextareaInput";
import { CheckBoxItem } from "../../app/models/common/checkBoxItem";
import AppDeclarationCheckbox from "./models/AppDeclarationCheckbox";
import RequestFormModel from "./models/requestFormModel";
import { ContactClassmateValidationSchema } from "./models/requestValidationSchemas";
import { toast } from "react-toastify";
import { RequestTypesEnum } from "../../app/enums/requestTypesEnum";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { CreateRequestTaskCommand } from "../../app/models/request/createRequestTaskCommand";
import { submitRequestAsync } from "../../app/slices/requestSlice";
import { useAppDispatch } from "../../app/store/configureStore";
import { API_REQUEST_FULLFILLED, MESSAGE_PROCESSING_DETAILS, MESSAGE_SUBMIT } from "../../app/utils/constant";
import SuccessResponse from "./models/SuccessResponse";

export default function ContactClassmates() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(ContactClassmateValidationSchema)
    });
    const { control, trigger, reset, formState: { isDirty } } = methods;
    const formObj = methods.watch();

    const {
        ContactClassmates: {
            DetailsOfClassmate,
            ContactClassmatesAreTheDetailsCorrect
        }
    } = RequestFormModel;

    const [declarationOption,] = useState<CheckBoxItem>({
        id: ContactClassmatesAreTheDetailsCorrect.name,
        value: ''
    });

    const dispatch = useAppDispatch();

    const [pageLoading, setPageLoading] = useState(false);
    const [isRequestSubmitted, setIsRequestSubmitted] = useState(false);

    const MapToRequestDetails = () => {
        let requestObj: CreateRequestTaskCommand = {
            requestTypeCode: RequestTypesEnum.ContactClassmate.toString(),
            details: `${DetailsOfClassmate.label.replace('*', '')}: ${formObj.DetailsOfClassmate}\n${ContactClassmatesAreTheDetailsCorrect.labelText}: ${formObj.ContactClassmatesAreTheDetailsCorrect}`,
            subject: ''
        };

        return requestObj;
    }

    const successMessage = <>
        Your request has been queued, we will endeavour to make contact on your behalf.<br /><br />
        Should more information be required a member of the Alumni team will be in contact shortly.
    </>

    const handleSave = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setPageLoading(true);
                dispatch(submitRequestAsync(MapToRequestDetails())).then((requestResponse: any) => {
                    if (requestResponse.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                        reset({
                            [DetailsOfClassmate.name]: '',
                            [ContactClassmatesAreTheDetailsCorrect.name]: false
                        });
                        setPageLoading(false);
                        setIsRequestSubmitted(true);
                        toast.success(successMessage);
                    }
                });
            }
        }
    };

    if (pageLoading === true) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    if (isRequestSubmitted === true) {
        return <SuccessResponse message={successMessage} />
    }

    return <>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ ml: '2%', mt: '0%', width: '96%' }}>

                <Grid item xs={12}>
                    <BackToRequests />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant='h4'>Contact a classmate</Typography>
                </Grid>

                <Grid item xs={12} sx={{ marginRight: '2%', marginBottom: '2%' }}>
                    <Typography variant='subtitle1'>
                        We are happy to help you reconnect with classmates where possible.
                        Please complete the form below and we will endeavour to make contact
                        on your behalf. Please note we are unable to share classmates' contact
                        details without their permission.
                        <br></br>
                        <br></br>
                        Include the first name and surname of your classmate, their class year and a message for them.
                    </Typography>
                </Grid>
            </Grid>
        </Box>

        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12}>
                    <AppTextareaInput
                        control={control}
                        name={DetailsOfClassmate.name}
                        label={DetailsOfClassmate.label}
                    />
                </Grid>

                <Grid item xs={12}>
                    <AppDeclarationCheckbox
                        control={control}
                        name={ContactClassmatesAreTheDetailsCorrect.name}
                        label={ContactClassmatesAreTheDetailsCorrect.label}
                        option={declarationOption} />
                </Grid>

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container rowSpacing={4}>
                <Grid item xs={12}></Grid>

                <Grid item xs={8}></Grid>

                <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <Button variant="contained" color="primary" onClick={handleSave}>
                        {MESSAGE_SUBMIT}
                    </Button>
                </Grid>

                <Grid item xs={1}></Grid>
            </Grid>
        </Box>
    </>
}
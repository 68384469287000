import { FormProvider, useForm } from "react-hook-form";
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import cpdFormModel from "../../common/cpdFormModel";
import { useEffect, useRef, useState } from "react";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { SearchCreditRecordsQuery } from "../../models/searchCreditRecordsQuery";
import { getCreditRecordsAsync, getDocumentsAsync } from "../../../../app/slices/creditsSlice";
import { selectAllWrapperToAutocompleteItems } from "../../../../app/utils/util";
import CpdDataList from "./CpdDataList";
import { CSVLink } from "react-csv";
import AppMultiSelectList from "../../../../app/components/AppMultiSelectList";
import { MapCategoriesToAutocompleteItems, MapStatusesToAutocompleteItems } from "../../../../app/utils/convertStaticDataToDropdownItems";
import { useMsal } from "@azure/msal-react";
import { UserClaims } from "../../../../app/enums/userClaims";
import AppAutocomplete from "../../../../app/components/AppAutocomplete";
import { useReferenceData } from "../../../../app/customHooks/useReferenceData";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { CreditRecord } from "../../models/creditRecord";
import { useGetPcsEnrolmentYears } from "../../../../app/customHooks/useGetPcsEnrolmentYears";
import { DropdownItem } from "../../../../app/models/common/dropdownItem";

const {
    formField: {
        Year,
        Category,
        CategoryFilter,
        Status
    }
} = cpdFormModel;

export default function ViewCpdForm() {

    const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);

    const methods = useForm({
        mode: 'all',
        defaultValues: {
            [Year.name]: ['']
        }
    });

    const { control, reset, getValues } = methods;

    const dispatch = useAppDispatch();
    const { cpdCategories, cpdRecordStatuses, activityTypes, goodPracticeDomains, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { creditRecords, documents, getCpdStatus, getDocumentsStatus, getCreditRecordsStatus } = useAppSelector(state => state.credits);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);

    const { accounts } = useMsal();

    useEffect(() => {
        if (accounts.length > 0 && accounts[0]?.idTokenClaims) {
            setPersonId(accounts[0].idTokenClaims[UserClaims.EXTENSION_PORTALCRM_PERSONID] as string);
        }
    }, [accounts]);

    const [personId, setPersonId] = useState('');
    const [distinctEnrolmentYears, setDistinctEnrolmentYears] = useState<DropdownItem[]>([]);

    useReferenceData();
    useGetPcsEnrolmentYears();

    useEffect(() => {
        if (enrolmentYears && enrolmentYears.length > 0) {
            const years = enrolmentYears?.slice()
                .sort((a, b) => b.year - a.year).map(x => String(x.year))
                .filter((value, index, self) => self.indexOf(value) === index);

            let yearsList: DropdownItem[] = [];

            years?.map((item: string) => {
                yearsList.push({ id: item, value: item, code: item });
                return true;
            });
            setDistinctEnrolmentYears(yearsList);
        }
    }, [enrolmentYears]);

    useEffect(() => {
        if (distinctEnrolmentYears.length > 0 && distinctEnrolmentYears[0].value) {
            reset({
                [Year.name]: [distinctEnrolmentYears[0].value]
            });
        }
    }, [distinctEnrolmentYears]);

    useEffect(() => {
        dispatch(getDocumentsAsync());
    }, [dispatch]);

    useEffect(() => {
        const searchObj: SearchCreditRecordsQuery = {
            personId: personId,
            year: getValues([Year.name])[0],
            cpdCategoryID: getValues([Category.name]).toString(),
            cpdRecordStatusID: getValues([Status.name]).toString()
        };

        dispatch(getCreditRecordsAsync(searchObj));
    }, [dispatch]);

    useEffect(() => {
        if (cpdCategories && cpdRecordStatuses && activityTypes && goodPracticeDomains) {
            onSearchClick();
        }
    }, [cpdCategories, cpdRecordStatuses, activityTypes, goodPracticeDomains]);

    function onSearchClick() {
        if (personId !== '') {
            let categoryId = getValues([Category.name]).toString();
            let statusId = getValues([Status.name]).toString();

            const searchObj: SearchCreditRecordsQuery = {
                personId: personId,
                year: getValues(Year.name),
                cpdCategoryID: categoryId === "0" ? "" : categoryId,
                cpdRecordStatusID: statusId === "0" ? "" : statusId
            };

            dispatch(getCreditRecordsAsync(searchObj));
        }
    }

    function formatCreditRecordList(data: CreditRecord[] | null) {
        if (data === null) return [];

        let cpdRecords: any[] = [];

        data.map((item: CreditRecord) => {
            return cpdRecords.push({
                activityDate: item.activityDate,
                creditRecordId: item.creditRecordId,
                personId: item.personId,
                cpdCategoryId: item.cpdCategoryId,
                cpdCategory: cpdCategories?.find(x => x.id === item.cpdCategoryId)?.value,
                activityTypeId: item.activityTypeId,
                activityType: activityTypes?.find(x => x.id === item.activityTypeId)?.value,
                goodPracticeDomainIds: item.goodPracticeDomainIds,
                credits: item.credits,
                description: item.description,
                referenceNumber: item.referenceNumber,
                purpose: item.purpose,
                cpdRecordStatusId: item.cpdRecordStatusId,
                cpdRecordStatus: cpdRecordStatuses?.find(x => x.id === item.cpdRecordStatusId)?.value,
                noOfDocuments: documents?.filter(x => x.directory === item.creditRecordId).length
            });
        });

        return cpdRecords;
    }

    const headers = [
        {
            label: "Date of activity",
            key: "activityDate"
        },
        {
            label: "Category",
            key: "cpdCategory"
        },
        {
            label: "Activity type",
            key: "activityType"
        },
        {
            label: "Activity description",
            key: "description"
        },
        {
            label: "Credit",
            key: "credits"
        },
        {
            label: "Status",
            key: "cpdRecordStatus"
        },
        {
            label: "No. of Documents attached",
            key: "noOfDocuments"
        }
    ];

    if (getCpdStatus === ApiRequestStatus.Pending || staticDataStateStatus === ApiRequestStatus.Pending ||
        getCreditRecordsStatus === ApiRequestStatus.Pending || getDocumentsStatus === ApiRequestStatus.Pending ||
        enrolmentYearsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <>
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <FormProvider {...methods}>

                <Typography variant='h4'>Search CPD Records</Typography>

                <Grid container spacing={2}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={3}>
                        <AppMultiSelectList control={control} name={Year.name} label={Year.label}
                            items={distinctEnrolmentYears} />
                    </Grid>

                    <Grid item xs={3}>
                        <AppAutocomplete control={control} name={CategoryFilter.name} label={CategoryFilter.label}
                            items={selectAllWrapperToAutocompleteItems(MapCategoriesToAutocompleteItems(cpdCategories ?? []))} />
                    </Grid>

                    <Grid item xs={3}>
                        <AppAutocomplete control={control} name={Status.name} label={Status.label}
                            items={selectAllWrapperToAutocompleteItems(MapStatusesToAutocompleteItems(cpdRecordStatuses ?? []))} />
                    </Grid>

                    <Grid item xs={3} sx={{ display: 'flex', justifyContent: 'left' }}>
                        <Button variant="contained" sx={{ m: "8px" }} color='error'
                            onClick={onSearchClick}>Search</Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={12}>
                        <CpdDataList />
                    </Grid>
                </Grid>

                <Grid container spacing={2}>

                    <Grid item xs={12}></Grid>

                    <Grid item xs={7}></Grid>

                    <Grid item xs={5}>
                        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                            <Button variant="contained" onClick={() => { csvLinkRef?.current?.link.click() }}
                                color='error'>Download</Button>
                            <CSVLink data={formatCreditRecordList(creditRecords)} headers={headers}
                                filename={`CPD Records`} ref={csvLinkRef} hidden={true}>Download</CSVLink>
                        </Box>
                    </Grid>
                </Grid>
            </FormProvider>
        </Paper>
    </>
}

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { RadioButtonValue } from '../models/radioButtonValue';
import { UseControllerProps, useController } from 'react-hook-form';
import { FormHelperText, Grid } from '@mui/material';
import AppInformationText from './AppInformationText';
import React from 'react';

interface Props extends UseControllerProps {
    label: string;
    items: RadioButtonValue[],
    information_text?: string
}

export default function AppRadioButtonsGroup(props: Props) {
    const { label, name, items, information_text } = props;
    const { fieldState, field } = useController({ ...props });

    return (
        <>
            <Grid container rowSpacing={4}>

                <Grid item xs={12} sm={5}>
                    {label}
                </Grid>

                <Grid item xs={0} sm={1}></Grid>

                <Grid item xs={11} sm={5}>
                    <RadioGroup
                        row
                        aria-labelledby="rcsi-row-radio-buttons-group-label"
                        value={field.value === undefined ? "" : field.value}
                        name={name}
                        onChange={(event, value) => field.onChange(value)}
                    >
                        {items.map((item: RadioButtonValue, index: number) => (
                            <React.Fragment key={index}>
                                <FormControlLabel value={item.value} control={<Radio />} label={item.label} />
                            </React.Fragment>
                        ))}
                    </RadioGroup>
                </Grid>

                <Grid item xs={1}>
                    <AppInformationText information_text={information_text} />
                </Grid>

                <Grid item xs={11} sm={12}>
                    {fieldState.error &&
                        <FormHelperText sx={{ color: 'primary.main' }}>{fieldState.error.message}</FormHelperText>}
                </Grid>

            </Grid>
        </>
    );
}
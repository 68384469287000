import { Box, Button, Grid, Typography } from "@mui/material";
import AppTextareaInput from "../../../../../app/components/AppTextareaInput";
import { useForm } from "react-hook-form";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { updatePlannedActivityAsync } from "../../../../../app/slices/pcsPdpSlice";
import { PdpStatusEnum } from "../../../models/pdpStatusEnum";
import { toast } from "react-toastify";
import { PlannedActivity } from "../../../models/plannedActivity";
import { yupResolver } from "@hookform/resolvers/yup";
import { plannedActivitiesValidationSchema } from "../../../common/pcsPlanValidationSchema";
import {
    API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SAVED,
    MESSAGE_PROCESSING_DETAILS
} from "../../../../../app/utils/constant";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import AppLabelField from "../../../../../app/components/AppLabelField";
import { PcsEnrolmentYear } from "../../../models/pcsEnrolmentYear";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import AppInformationText from "../../../../../app/components/AppInformationText";
import { PcsPdpStatus } from "../../../../../app/models/staticData/pcsPdpStatus";

export function UpdatePlannedActivities() {
    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(plannedActivitiesValidationSchema)
    });

    const { control, reset, getValues, trigger } = methods;
    const { AccreditedContinuedEducationActivity, WorkBasedLearning, PracticeReview, EngagedInPracticeOfMedicine }
        = pcsPlanFormModel.formField;

    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const [canUpdatePlannedActivities, setCanUpdatePlannedActivities] = useState<boolean>();
    const [enrolmentYear, setEnrolmentYear] = useState<PcsEnrolmentYear>();
    const [currentEnrolmentYearStatus, setCurrentEnrolmentYearStatus] = useState<PcsPdpStatus>();

    const dispatch = useAppDispatch();

    const { pcsPdpStatuses } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear } = useAppSelector(state => state.pcs);
    const { updatePlannedActivityStatus } = useAppSelector(state => state.pcsPdp);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            reset({
                [AccreditedContinuedEducationActivity.name]: enrolmentYear?.pdpExternal,
                [WorkBasedLearning.name]: enrolmentYear?.pdpWorkBasedLearning,
                [PracticeReview.name]: enrolmentYear?.pdpPracticeReview,
                [EngagedInPracticeOfMedicine.name]: enrolmentYear?.engagedInPracticeOfMedicine ?
                    enrolmentYear?.engagedInPracticeOfMedicine : false
            });
        }
    }, [enrolmentYears, enrolmentYearId, reset]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0) {
            setEnrolmentYear(enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [enrolmentYears, enrolmentYearId]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCurrentEnrolmentYearStatus(pcsPdpStatus);
            setCanUpdatePlannedActivities(pcsPdpStatus?.code === PdpStatusEnum.Draft ||
                pcsPdpStatus?.code === PdpStatusEnum.NotStarted ||
                pcsPdpStatus?.code === PdpStatusEnum.Submitted ||
                pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestCancelled ||
                pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestDeclined ||
                pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestInvalid);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear, enrolmentYearId]);

    const savePlannedActivities = async (isSubmitted: boolean) => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let plannedActivity = mapToPlannedActivitiesDto(isSubmitted);
            dispatch(updatePlannedActivityAsync(plannedActivity)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            toast.success(MESSAGE_DETAILS_SAVED);
                        }
                    });
                }
            });
        }
    };

    const mapToPlannedActivitiesDto = (isSubmitted: boolean) => {
        const data: PlannedActivity = {
            external: getValues([AccreditedContinuedEducationActivity.name]).toString(),
            workBasedLearning: getValues([WorkBasedLearning.name]).toString(),
            practiceReview: getValues([PracticeReview.name]).toString(),
            enrolmentYearId: enrolmentYearId,
            submit: isSubmitted
        }
        return data;
    }

    if (updatePlannedActivityStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <Box sx={{ width: '100%' }}>

        <Grid container rowSpacing={4} sx={{ paddingTop: "5%" }}>

            <Grid item xs={8} sm={5} lg={3}>
                <Typography variant='h5' sx={{ fontWeight: '550' }}>Planned activities</Typography>
            </Grid>

            <Grid item xs={1} sm={3} lg={3} sx={{ marginTop: '-7px' }}>
                <AppInformationText information_text={'Outline activities that will assist you in meeting your CPD requirements.'} />
            </Grid>

            <Grid item xs={3} sm={4} lg={3}></Grid>

            {canUpdatePlannedActivities === true && <>
                <Grid item xs={12}>
                    <AppTextareaInput control={control}
                        name={AccreditedContinuedEducationActivity.name}
                        label={AccreditedContinuedEducationActivity.label} />
                </Grid>
            </>}

            {canUpdatePlannedActivities === false && <>
                <Grid item xs={12}>
                    <AppLabelField label={AccreditedContinuedEducationActivity.label.replace("*", "")}
                        value={enrolmentYear?.pdpExternal ? enrolmentYear.pdpExternal : ''} />
                </Grid>
            </>}

            {canUpdatePlannedActivities === true && <>
                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={WorkBasedLearning.name}
                        label={WorkBasedLearning.label} disabled={!canUpdatePlannedActivities} />
                </Grid>
            </>}

            {canUpdatePlannedActivities === false && <>
                <Grid item xs={12}>
                    <AppLabelField label={WorkBasedLearning.label.replace("*", "")}
                        value={enrolmentYear?.pdpWorkBasedLearning ?
                            enrolmentYear.pdpWorkBasedLearning : ''} />
                </Grid>
            </>}

            {canUpdatePlannedActivities === true && <>
                <Grid item xs={12}>
                    <AppTextareaInput control={control} name={PracticeReview.name}
                        label={enrolmentYear?.engagedInPracticeOfMedicine === true ?
                            PracticeReview.label : PracticeReview.label.replace("*", "")}
                        disabled={!canUpdatePlannedActivities} />
                </Grid>
            </>}

            {canUpdatePlannedActivities === false && <>
                <Grid item xs={12}>
                    <AppLabelField label={PracticeReview.label.replace("*", "")}
                        value={enrolmentYear?.pdpPracticeReview ? enrolmentYear.pdpPracticeReview : ''} />
                </Grid>
            </>}

            {canUpdatePlannedActivities === true &&
                <Grid item xs={6} sm={6} lg={9}>
                    <Button variant="contained" color="success" onClick={() => savePlannedActivities(true)}>
                        Submit
                    </Button>
                </Grid>
            }

            {(currentEnrolmentYearStatus?.code === PdpStatusEnum.Draft ||
                currentEnrolmentYearStatus?.code === PdpStatusEnum.NotStarted) &&
                <Grid item xs={6} sm={6} lg={3}>
                    <Button variant="contained" color="warning" onClick={() => savePlannedActivities(false)}>
                        Save as a Draft
                    </Button>
                </Grid>}

        </Grid>
    </Box>
}
import { Box, Button, Grid, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import AppTextInput from "../../../../../app/components/AppTextInput";
import pcsPlanFormModel from "../../../common/pcsPlanFormModel";
import { MapTitlesToAutocompleteItems } from "../../../../../app/utils/convertStaticDataToDropdownItems";
import { useAppDispatch, useAppSelector } from "../../../../../app/store/configureStore";
import { ApiRequestStatus } from "../../../../../app/enums/apiRequestStatus";
import LoadingComponent from "../../../../../app/layout/LoadingComponent";
import { confirmReviewerDetailsAsync, searchReviewerByImcNumberAsync, submitReviewerDetailsAsync }
    from "../../../../../app/slices/pcsPdpSlice";
import { ViewReviewer } from "../viewPlan/ViewReviewer";
import { ConfirmReviewerDetailsParameters } from "../../../models/confirmReviewerDetailsParameters";
import { API_REQUEST_FULLFILLED, MESSAGE_DETAILS_SENT_TO_REVIEWER, MESSAGE_PROCESSING_DETAILS }
    from "../../../../../app/utils/constant";
import { toast } from "react-toastify";
import { Link, useParams, useHistory } from "react-router-dom";
import { ReviewerDetails } from "../../../models/reviewerDetails";
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmReviewerDetailsValidationSchema } from "../../../common/pcsPlanValidationSchema";
import { useReferenceData } from "../../../../../app/customHooks/useReferenceData";
import { getPcsEnrolmentYearsAsync } from "../../../../../app/slices/pcsEnrolmentSlice";
import { AppPathEnum } from "../../../../../app/enums/appPathEnum";
import AppAutocomplete from "../../../../../app/components/AppAutocomplete";
import { TitleEnum } from "../../../../../app/enums/titleEnum";
import { Title } from "../../../../../app/models/staticData/title";

export default function ConfirmReviewerDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { Title, FirstName, Surname, IMCNumber, EmailAddress } = pcsPlanFormModel.formField;
    const methods = useForm({
        resolver: yupResolver(confirmReviewerDetailsValidationSchema)
    });

    const { control, getValues, trigger, watch, formState: { isDirty } } = methods;

    const [isReviewerFound, setIsReviewerFound] = useState(false);
    const [isReviewerSearched, setIsReviewerSearched] = useState(false);
    const [filteredTitles, setFilteredTitles] = useState<Title[]>([]);

    const history = useHistory();

    const dispatch = useAppDispatch();

    const { titles, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { reviewerDetails, confirmReviewerDetailsStatus, submitReviewerDetailsStatus,
        searchReviewerByImcNumberStatus } = useAppSelector(state => state.pcsPdp);
    const { enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);

    useReferenceData();

    useEffect(() => {
        if (titles !== null && titles.length > 0) {
            const titleList = titles.filter(x => x.value.toLowerCase() === TitleEnum.Dr ||
                x.value.toLowerCase() === TitleEnum.Mr || x.value.toLowerCase() === TitleEnum.Mrs ||
                x.value.toLowerCase() === TitleEnum.Ms || x.value.toLowerCase() === TitleEnum.Miss ||
                x.value.toLowerCase() === TitleEnum.Professor);
            setFilteredTitles(titleList);
        }
    }, [titles]);

    const searchButtonClick = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            if (isDirty === true) {
                setIsReviewerSearched(true);
                dispatch(searchReviewerByImcNumberAsync(getValues(IMCNumber.name)))
                    .then((response) => {
                        if (response.payload !== "") {
                            setIsReviewerFound(true);
                        } else {
                            setIsReviewerFound(false);
                        }
                    }).catch(() => setIsReviewerFound(false));
            }
        }
    }

    useEffect(() => {
        watch((_, { name, type }) => {
            if (name === IMCNumber.name && type === 'change') {
                setIsReviewerSearched(false);
            }
        });
    }, [watch]);

    const confirmReviewer = async () => {
        if (reviewerDetails?.id) {
            let pcsPlanDetailsParameters: ConfirmReviewerDetailsParameters = {
                reviewerPersonId: reviewerDetails?.id,
                enrolmentYearId: enrolmentYearId
            };
            dispatch(confirmReviewerDetailsAsync(pcsPlanDetailsParameters)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                            toast.success(MESSAGE_DETAILS_SENT_TO_REVIEWER);
                        }
                    });
                }
            });
        }
    }

    const submitReviewer = async () => {
        const isStepValid = await trigger();

        if (isStepValid) {
            let reviewerDetails = mapToReviewerDetailsDto();
            dispatch(submitReviewerDetailsAsync(reviewerDetails)).then((response: any) => {
                if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                    dispatch(getPcsEnrolmentYearsAsync()).then((response) => {
                        if (response.meta.requestStatus.toLowerCase() === API_REQUEST_FULLFILLED) {
                            history.push(`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`);
                            toast.success(MESSAGE_DETAILS_SENT_TO_REVIEWER);
                        }
                    });
                }
            });
        }
    }

    const mapToReviewerDetailsDto = () => {
        const data: ReviewerDetails = {
            enrolmentYearId: enrolmentYearId,
            titleId: getValues([Title.name]).toString(),
            firstName: getValues([FirstName.name]).toString(),
            surname: getValues([Surname.name]).toString(),
            imcNumber: getValues([IMCNumber.name]).toString(),
            email: getValues([EmailAddress.name]).toString()
        }
        return data;
    }

    const userFoundFilter = () => {
        return getValues([IMCNumber.name]).toString() !== "" &&
            isReviewerSearched === true && isReviewerFound === true;
    }

    const userNotFoundFilter = () => {
        return getValues([IMCNumber.name]).toString() !== "" &&
            isReviewerSearched === true && isReviewerFound === false;
    }

    if (staticDataStateStatus === ApiRequestStatus.Pending
        || confirmReviewerDetailsStatus === ApiRequestStatus.Pending
        || submitReviewerDetailsStatus === ApiRequestStatus.Pending
        || searchReviewerByImcNumberStatus === ApiRequestStatus.Pending
        || enrolmentYearsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Box sx={{ width: '100%' }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'left' }}>
                    <Typography variant='h4'>Find reviewer</Typography>
                </Grid>

                <Grid item xs={11}>
                    <Typography variant='subtitle1'>
                        Search for a reviewer by entering their IMC  number and clicking Search.
                    </Typography>
                </Grid>

                <Grid item xs={1}></Grid>

                <Grid item xs={8} sm={9} md={10} lg={10}>
                    <AppTextInput control={control} label={IMCNumber.label} name={IMCNumber.name} />
                </Grid>

                <Grid item xs={4} sm={3} md={2} lg={2}>
                    <Button variant="contained" type='button' onClick={searchButtonClick} sx={{ mt: 1, height: 'auto' }}>
                        Search
                    </Button>
                </Grid>

                {userNotFoundFilter() === true &&
                    <>
                        <Grid item xs={11}>
                            <Typography variant='subtitle1'>
                                <div style={{ color: 'primary.main' }}>Please note that the reviewer's IMC number was not found in our database.</div>
                                Please complete the below fields and click submit. The PCS office will
                                validate these details and send the request on your behalf.
                            </Typography>
                        </Grid>

                        <Grid item xs={1}></Grid>

                        <Grid item xs={12}>
                            <AppAutocomplete control={control} label={Title.label} name={Title.name}
                                items={MapTitlesToAutocompleteItems(filteredTitles ?? [])} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput control={control} label={FirstName.label} name={FirstName.name} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput control={control} label={Surname.label} name={Surname.name} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput control={control} label={IMCNumber.label} name={IMCNumber.name} />
                        </Grid>

                        <Grid item xs={12}>
                            <AppTextInput control={control} label={EmailAddress.label} name={EmailAddress.name} />
                        </Grid>
                    </>
                }

                {userFoundFilter() === true &&
                    <>
                        <Grid item xs={12}>
                            <Typography variant='subtitle1'>
                                Please see below reviewer details. Please note that requests
                                will be sent to the email address we have on our records.
                                To proceed with this request click confirm.
                            </Typography>
                        </Grid>

                        <ViewReviewer />
                    </>
                }

                {userFoundFilter() === true &&
                    <Grid item xs={9} sm={3}>
                        <Button variant="contained" color="primary" onClick={() => confirmReviewer()}>
                            Confirm
                        </Button>
                    </Grid>}

                {userNotFoundFilter() === true &&
                    <Grid item xs={9} sm={3}>
                        <Button variant="contained" color="primary" onClick={() => submitReviewer()}>
                            Submit
                        </Button>
                    </Grid>
                }

                {(userFoundFilter() === true || userNotFoundFilter() === true) &&
                    <Grid item xs={3} sm={2}>
                        <Button component={Link} to={`${AppPathEnum.PCS_PLAN_DETAILS}/${enrolmentYearId}${window.location.search}`}>
                            Cancel
                        </Button>
                    </Grid>
                }

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </FormProvider>
}
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../app/store/configureStore";
import { Link, useHistory, useParams } from "react-router-dom";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../app/utils/constant";
import { ApiRequestStatus } from "../../../app/enums/apiRequestStatus";
import { getPcsCurrentYearAsync } from "../../../app/slices/pcsSlice";
import { ViewReviewerDetails } from "../planDetails/ViewReviewerDetails";
import { ViewPlannedActivities } from "../planDetails/ViewPlannedActivities";
import { WorkDetails } from "../planDetails/WorkDetails";
import ReviewerComments from "../planDetails/ReviewerComments";
import { Box, Button, Grid } from "@mui/material";
import { AppPathEnum } from "../../../app/enums/appPathEnum";
import { PcsEnrolmentYear } from "../../pcsPlan/models/pcsEnrolmentYear";
import { PdpStatusEnum } from "../../pcsPlan/models/pdpStatusEnum";
import { PcsPdpStatus } from "../../../app/models/staticData/pcsPdpStatus";
import { PcsPlanStatus } from "../planDetails/PcsPlanStatus";
import { getRevieweeDetailsAsync } from "../../../app/slices/pcsPdpSlice";

export default function PlanReviewerDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);
    const { pcsEnrolmentYears, getRevieweeDetailsStatus } = useAppSelector(state => state.pcsPdp);
    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);

    const history = useHistory();
    const methods = useForm();

    const [currentEnrolmentYear, setCurrentEnrolmentYear] = useState<PcsEnrolmentYear>();
    const [invalidStatuses, setInvalidStatuses] = useState<PcsPdpStatus[]>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (pcsPdpStatuses && pcsPdpStatuses?.length > 0) {
            setInvalidStatuses(pcsPdpStatuses?.filter(x => x.code !== PdpStatusEnum.ReviewRequestPending
                && x.code !== PdpStatusEnum.UnderReview));
        }
    }, [pcsPdpStatuses]);

    useEffect(() => {
        dispatch(getPcsCurrentYearAsync());
        dispatch(getRevieweeDetailsAsync(enrolmentYearId));
    }, [dispatch]);

    useEffect(() => {
        if (pcsEnrolmentYears && pcsEnrolmentYears.length > 0) {
            setCurrentEnrolmentYear(pcsEnrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId));
        }
    }, [pcsEnrolmentYears, enrolmentYearId]);

    if (currentYearStatus === ApiRequestStatus.Pending ||
        staticDataStateStatus === ApiRequestStatus.Pending ||
        getRevieweeDetailsStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    invalidStatuses && invalidStatuses?.map((x: PcsPdpStatus) => {
        if (x.id === currentEnrolmentYear?.pcsPdpReviewStatusId) {
            history.push(`${AppPathEnum.PCS_PLAN_REVIEW}${window.location.search}`);
        }
    });

    return <FormProvider {...methods}>
        <Box sx={{ width: '100%' }}>
            <Grid container rowSpacing={4} sx={{ margin: '2%' }}>
                <Grid item xs={12} sm={12}>
                    <Button component={Link} to={`${AppPathEnum.PCS_PLAN_REVIEW}${window.location.search}`} variant="contained">
                        Back to Professional Development Plans
                    </Button>
                </Grid>

                <PcsPlanStatus />
                <ViewReviewerDetails />
                {currentEnrolmentYear?.year === currentYear && <WorkDetails />}
                <ViewPlannedActivities />
                <ReviewerComments />
            </Grid>
        </Box>
    </FormProvider >
}

import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "../../../../app/store/configureStore";
import { Link, useParams } from "react-router-dom";
import { PdpStatusEnum } from "../../models/pdpStatusEnum";
import LoadingComponent from "../../../../app/layout/LoadingComponent";
import { MESSAGE_PROCESSING_DETAILS } from "../../../../app/utils/constant";
import { ApiRequestStatus } from "../../../../app/enums/apiRequestStatus";
import { getPcsEnrolmentYearsAsync } from "../../../../app/slices/pcsEnrolmentSlice";
import { getPcsCurrentYearAsync } from "../../../../app/slices/pcsSlice";
import { getReferenceDataAsync } from "../../../../app/slices/referenceDataSlice";
import { ActivityDetails } from "../planDetails/ActivityDetails";
import { UpdatePlannedActivities } from "../planDetails/updatePlan/UpdatePlannedActivities";
import { Box, Grid, Button, Paper } from "@mui/material";
import { AppPathEnum } from "../../../../app/enums/appPathEnum";
import { ViewReviewer } from "../planDetails/viewPlan/ViewReviewer";
import { ViewFeedback } from "../planDetails/viewPlan/ViewFeedback";
import { PcsPdpStatus } from "../../../../app/models/staticData/pcsPdpStatus";

export default function PlanDetails() {
    const { enrolmentYearId } = useParams<{ enrolmentYearId: string }>();

    const { pcsPdpStatuses, staticDataStateStatus } = useAppSelector(state => state.ReferenceData);
    const { enrolmentYears, enrolmentYearsStatus } = useAppSelector(state => state.pcsEnrolment);
    const { currentYear, currentYearStatus } = useAppSelector(state => state.pcs);

    const methods = useForm();
    const [canAddReviewer, setCanAddReviewer] = useState<boolean>();
    const [canViewReviewerDetails, setCanViewReviewerDetails] = useState<boolean>();
    const [pcsPdpStatus, setPcsPdpStatus] = useState<PcsPdpStatus>();

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(getReferenceDataAsync());
        dispatch(getPcsEnrolmentYearsAsync());
        dispatch(getPcsCurrentYearAsync());
    }, [dispatch]);

    useEffect(() => {
        if (enrolmentYears && enrolmentYears?.length > 0 && pcsPdpStatuses && currentYear) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            const pcsPdpStatus = pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId);

            setCanAddReviewer((pcsPdpStatus?.code === PdpStatusEnum.Submitted
                || pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestCancelled
                || pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestDeclined
                || pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestInvalid)
                && enrolmentYear?.year === currentYear);
            setCanViewReviewerDetails(pcsPdpStatus?.code === PdpStatusEnum.ReviewRequestPending
                || pcsPdpStatus?.code === PdpStatusEnum.UnderReview
                || pcsPdpStatus?.code === PdpStatusEnum.Reviewed);
        }
    }, [enrolmentYears, pcsPdpStatuses, currentYear, enrolmentYearId]);

    useEffect(() => {
        if (pcsPdpStatuses && enrolmentYears) {
            const enrolmentYear = enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId);
            setPcsPdpStatus(pcsPdpStatuses.find(x => x.id === enrolmentYear?.pcsPdpReviewStatusId));
        }
    }, [pcsPdpStatuses, enrolmentYears, enrolmentYearId]);

    if (enrolmentYearsStatus === ApiRequestStatus.Pending
        || staticDataStateStatus === ApiRequestStatus.Pending
        || currentYearStatus === ApiRequestStatus.Pending) {
        return <LoadingComponent message={MESSAGE_PROCESSING_DETAILS} />
    }

    return <FormProvider {...methods}>
        <Box sx={{ minWidth: "100%" }} component={Paper}>
            <Grid container rowSpacing={4} sx={{ margin: "2%" }}>

                <ActivityDetails />
                <UpdatePlannedActivities />
                {canViewReviewerDetails === true && <ViewReviewer />}

                <Grid item xs={12}></Grid>

                {canAddReviewer === true &&
                    <Grid item xs={9} sm={5}>
                        <Button variant="contained" color="primary" component={Link}
                            to={`${AppPathEnum.PCS_REVIEWER_DETAILS}/${enrolmentYearId}${window.location.search}`}>
                            Add reviewer
                        </Button>
                    </Grid>
                }

                {enrolmentYears?.find(x => x.enrolmentYearId === enrolmentYearId) &&
                    (pcsPdpStatus?.code === PdpStatusEnum.Reviewed === true ||
                        pcsPdpStatus?.code === PdpStatusEnum.PdpReflectionComplete === true
                    ) && <ViewFeedback />}

                <Grid item xs={12}></Grid>

            </Grid>
        </Box>
    </FormProvider>
}
